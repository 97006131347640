import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonGetSaga, getCommonSaga, ge, serviceCommonPostSaga, serviceStatusSaga, queryCommonSaga } from "../sagaUtils";
import store from "../index";
import { contextDataFailure, contextDataSuccess, contextListFailure, contextListSuccess, deleteContextFailure, deleteContextSuccess, deleteFileFailure, deleteFileSuccess, qnaFailure, qnaSuccess, serviceStatusFailure, serviceStatusStart, serviceStatusSuccess, startLLMServiceFailure, startLLMServiceSuccess, stopLLMServiceFailure, stopLLMServiceSuccess, trainLLMCancelFailure, trainLLMCancelSuccess, trainLLMFailure, trainLLMSuccess, trainedFileInfoFailure, trainedFileInfoStart, trainedFileInfoSuccess, uploadFileFailure, uploadFileSuccess } from "../slices/CommonSlice";

function* serviceStatusAPI(action) {
  yield serviceStatusSaga({
    apiUrl: "status",
    payload: action.payload,
    successNotify: false,
    success: serviceStatusSuccess,
    failure: serviceStatusFailure,
  });
}

function* uploadFileAPI(action) {
  yield getCommonSaga({
    apiUrl: "uploadfile?user_id=123&bot_id=12345",
    payload: action.payload,
    successNotify: true,
    success: uploadFileSuccess,
    failure: uploadFileFailure,
  });
}

function* trainedFileInfoAPI(action) {
  yield getCommonSaga({
    apiUrl: "api/v1/file_info",
    payload: action.payload,
    successNotify: false,
    success: trainedFileInfoSuccess,
    failure: trainedFileInfoFailure,
  });
}

function* deleteFileAPI(action) {
  const trainedFileInfoData = yield select(state => state.common.trainedFileInfo.data);
  yield getCommonSaga({
    apiUrl: "api/v1/delete_file",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(deleteFileSuccess(data));
      store.dispatch(trainedFileInfoSuccess({
        ...trainedFileInfoData,
        data: {
          ...trainedFileInfoData.data,
          trained_file_names: trainedFileInfoData.data.trained_file_names.filter(item => item !== action.payload.file_name),
          non_trained_file_names: trainedFileInfoData.data.non_trained_file_names.filter(item => item !== action.payload.file_name),
        }
      }));
    },
    failure: deleteFileFailure,
  });
}

function* startLLMServiceAPI(action) {
  yield serviceCommonPostSaga({
    apiUrl: "start",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(startLLMServiceSuccess(data));
      store.dispatch(serviceStatusStart());
    },
    failure: startLLMServiceFailure,
  });
}

function* stopLLMServiceAPI(action) {
  yield serviceCommonPostSaga({
    apiUrl: "stop",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(stopLLMServiceSuccess(data));
      store.dispatch(serviceStatusStart());
    },
    failure: stopLLMServiceFailure,
  });
}

function* trainLLMAPI(action) {
  const userId = yield select(state => state.common.userId)
  yield queryCommonSaga({
    apiUrl: "api/v1/train",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(trainLLMSuccess(data));
      userId && store.dispatch(trainedFileInfoStart({
        bot_id: userId,
        user_id: userId,
      }));
    },
    failure: trainLLMFailure,
  });
}

function* trainLLMCancelAPI(action) {
  yield queryCommonSaga({
    apiUrl: "train_llm_cancel",
    payload: action.payload,
    successNotify: false,
    success: trainLLMCancelSuccess,
    failure: trainLLMCancelFailure,
  });
}

function* qnaAPI(action) {
  const contextData = yield select(state => state.common.contextData.data.data);
  yield queryCommonSaga({
    apiUrl: "api/v1/query",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(qnaSuccess(data));
    },
    failure: qnaFailure,
  });
}

function* contextListAPI(action) {
  yield getCommonGetSaga({
    apiUrl: "api/v1/get_all_context_id",
    payload: action.payload,
    successNotify: false,
    success: contextListSuccess,
    failure: contextListFailure,
  });
}

function* contextDataAPI(action) {
  yield getCommonSaga({
    apiUrl: "api/v1/get_context_data",
    payload: action.payload,
    successNotify: false,
    success: contextDataSuccess,
    failure: contextDataFailure,
  });
}

function* deleteContextAPI(action) {
  yield getCommonSaga({
    apiUrl: "api/v1/delete_context_data",
    payload: action.payload,
    successNotify: true,
    success: deleteContextSuccess,
    failure: deleteContextFailure,
  });
}

export default function* CommonSaga() {
  yield all([
    yield takeLatest("common/serviceStatusStart", serviceStatusAPI),
    yield takeLatest("common/uploadFileStart", uploadFileAPI),
    yield takeLatest("common/trainedFileInfoStart", trainedFileInfoAPI),
    yield takeLatest("common/deleteFileStart", deleteFileAPI),
    yield takeLatest("common/startLLMServiceStart", startLLMServiceAPI),
    yield takeLatest("common/stopLLMServiceStart", stopLLMServiceAPI),
    yield takeLatest("common/trainLLMStart", trainLLMAPI),
    yield takeLatest("common/trainLLMCancelStart", trainLLMCancelAPI),
    yield takeLatest("common/qnaStart", qnaAPI),
    yield takeLatest("common/contextListStart", contextListAPI),
    yield takeLatest("common/contextDataStart", contextDataAPI),
    yield takeLatest("common/deleteContextStart", deleteContextAPI),
  ]);
}
