import React from "react";
import { Image } from "react-bootstrap";

const NotChatFound = () => {
  return (
    <>
      <div className="no-chat-found-card">
        <div className="no-chat-found-img-sec">
            <Image
            className="no-chat-found-img"
            src={
                window.location.origin +
                "/img/no-chat-found-img.svg"
            }
            />
        </div>
        <div className="no-chat-found-info">
            <h4>No chats currently!</h4>
            <p>Begin entering prompts to display results</p>
        </div>
      </div>
    </>
  );
};

export default NotChatFound;
