import React from "react";
import { Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const WelcomePageIndex = (props) => {
  return (
    <>
      <div className="welcome-page-body-sec">
        <div className="welcome-page-box">
          <div className="welcome-left-sec">
            <h2>Welcome to HaiVE</h2>
            <div className="welcome-page-card">
              <p>
                I am your onboarding buddy, and I'm here to help if you have any
                questions. Please follow this tour to familiarize yourself with
                our platform.
              </p>
              <p>
                <small>
                  Experience seamless communication with the bot through this
                  application, where you can enjoy a variety of features:
                </small>
              </p>
              <div className="welcome-page-img-sec">
                <Image
                  className="welcome-page-img"
                  src={window.location.origin + "/img/welcome/welcome-img.png"}
                />
                <div className="welcome-page-btn-sec">
                  <Link to="/upload" className="get-started-btn">
                    Get Started
                  </Link>
                  <Button
                    onClick={() => localStorage.setItem("dashboard_tour", 0)}
                    className="default-btn"
                  >
                    Begin Tour
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="welcome-right-sec">
            <div className="welcome-instruction-content">
              <div className="welcome-instruction-icon-sec">
                <Image
                  className="welcome-instruction-icon"
                  src={window.location.origin + "/img/welcome/welcome-icon.png"}
                />
              </div>
              <h3>
                Seed the bot knowledge base by uploading training resources.
              </h3>
              <ul className="list-unstyled welcome-instruction-list">
                <li>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="22.76"
                      height="22.3251"
                      rx="11.1625"
                      fill="#195097"
                    ></rect>
                    <path
                      d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                      fill="white"
                      stroke="white"
                    ></path>
                    <rect
                      x="0.5"
                      y="0.5"
                      width="22.76"
                      height="22.3251"
                      rx="11.1625"
                      stroke="#195097"
                    ></rect>
                  </svg>
                  <span>
                    See what it's like when all the tools and data an agent
                    needs are unified on a single pane of glass, providing the
                    entire context of a customer's issue.
                  </span>
                </li>
                <li>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="22.76"
                      height="22.3251"
                      rx="11.1625"
                      fill="#195097"
                    ></rect>
                    <path
                      d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                      fill="white"
                      stroke="white"
                    ></path>
                    <rect
                      x="0.5"
                      y="0.5"
                      width="22.76"
                      height="22.3251"
                      rx="11.1625"
                      stroke="#195097"
                    ></rect>
                  </svg>
                  <span>
                    Watch how customer interactions can be converted seamlessly
                    between channels, keeping all data and context preserved.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomePageIndex;
