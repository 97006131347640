import React, { useEffect, useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  serviceStatusStart,
  toggleSidebar,
} from "../../../store/slices/CommonSlice";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";

const HeaderIndex = (props) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const [status, setStatus] = useState(0);
  const serviceStatus = useSelector((state) => state.common.serviceStatus);
  const [skipRender, setSkipRender] = React.useState(true);

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "online-status";
      case 0:
        return "offline-status";
      default:
        return "offline-status";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return "#197E23";
      case 0:
        return "#FF6A6A";
      default:
        return "#FF6A6A";
    }
  };

  useEffect(() => {
    if (!skipRender && !serviceStatus.buttonDisable && Object.keys(serviceStatus.data).length > 0) {
      setStatus(serviceStatus.data.status == "API is working" ? 1 : 0);
    }
    setSkipRender(false);
  }, [serviceStatus]);

  const getPageTitle = () => {
    switch (location.pathname) {
      case "/file-uploading":
        return "Prompt";
      case "/upload":
        return "Upload";
      case "/fileupload":
        return "File Upload";
      case "/chat":
        return "Chat";
      default:
        return "Dashboard";
    }
  };

  return (
    <>
      <div className="admin-ui-header-sec">
        <div className="" style={{ width: "100%", display:'flex', justifyContent:"space-between", alignItems:"center" }}>
          <div className="admin-ui-title-sec">
            <div
              className="admin-ui-collapse-btn"
              onClick={() => dispatch(toggleSidebar())}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#195097"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M24 3.5c0 .83-.67 1.5-1.5 1.5h-21C.67 5 0 4.33 0 3.5S.67 2 1.5 2h21c.83 0 1.5.67 1.5 1.5zM6.5 20h-5c-.83 0-1.5.67-1.5 1.5S.67 23 1.5 23h5c.83 0 1.5-.67 1.5-1.5S7.33 20 6.5 20zm8-9h-13c-.83 0-1.5.67-1.5 1.5S.67 14 1.5 14h13c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z"></path>
              </svg>
            </div>
            <h2>{getPageTitle()}</h2>
          </div>
          <div className="status">
            <div className={getStatusClass(status)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                fill={getStatusColor(status)}
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
              </svg>
              LLM {status ? "Online" : "Offline"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderIndex;
