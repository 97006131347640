import React from "react";
import { Button, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { trainLLMStart } from "../../store/slices/CommonSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
const FileUploadingInitisate = (props) => {
  const dispatch = useDispatch();
  const trainedFileInfo = useSelector((state) => state.common.trainedFileInfo);
  const trainLLM = useSelector((state) => state.common.trainLLM);
  const userId = useSelector((state) => state.common.userId);

  return (
    <>
      {trainedFileInfo.buttonDisable ? (
        <Skeleton height={50} width={200} />
      ) : Object.keys(trainedFileInfo.data).length > 0 ? (
        <div className="file-upload-initisat-frame">
          {trainedFileInfo.data.data.non_trained_file_names.length > 0 ? (
            <div className="file-upload-initisat-status">
              <span className="file-initisat-success">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  x="0"
                  y="0"
                  enableBackground="new 0 0 507.506 507.506"
                  version="1.1"
                  viewBox="0 0 507.506 507.506"
                  xmlSpace="preserve"
                  fill="#195097"
                >
                  <path d="M163.865 436.934a54.228 54.228 0 01-38.4-15.915L9.369 304.966c-12.492-12.496-12.492-32.752 0-45.248 12.496-12.492 32.752-12.492 45.248 0l109.248 109.248L452.889 79.942c12.496-12.492 32.752-12.492 45.248 0 12.492 12.496 12.492 32.752 0 45.248L202.265 421.019a54.228 54.228 0 01-38.4 15.915z"></path>
                </svg>
              </span>
            </div>
          ) : (
            <Image
              style={{ width: "300px", marginBottom: "1em" }}
              className="file-upload-option-img"
              src={window.location.origin + "/img/upload/upload-right-img.png"}
              type="image/png"
            />
          )}
          <div className="file-upload-initisat-info">
            {trainedFileInfo.data.data.non_trained_file_names.length > 0 ? (
              <h3>
                {trainedFileInfo.data.data.non_trained_file_names.length} Files
                Uploaded Successfully
              </h3>
            ) : (
              <h3> Please Upload Files To Initiate Training.</h3>
            )}
            {trainedFileInfo.data.data.non_trained_file_names.length > 0 && (
              <p>All the files are prepared and ready for bot training</p>
            )}
          </div>
          <div className="file-upload-initisat-action">
            <Button
              disabled={
                trainedFileInfo.data.data.non_trained_file_names.length < 1 || trainLLM.buttonDisable
              }
              onClick={() => {
                dispatch(
                  trainLLMStart({
                    user_id: userId,
                    bot_id: userId,
                  })
                );
                props.setStep(2);
              }}
              className="default-green-btn"
            >
              {trainLLM.buttonDisable ? "Loading" : "Initiate Training"}
            </Button>
            <Link
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
              to="/upload"
              className="btn-no-border-bg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#195097"
              >
                <path d="M16.5 10.5h-3v-3a1.5 1.5 0 00-3 0v3h-3a1.5 1.5 0 000 3h3v3a1.5 1.5 0 003 0v-3h3a1.5 1.5 0 000-3z"></path>
              </svg>
              <span>Add Files...</span>
            </Link>
          </div>
        </div>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default FileUploadingInitisate;
