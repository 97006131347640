import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  sidebar: false,
  serviceStatus: getCommonInitialState(),
  uploadFile: getCommonInitialState(),
  trainLLM: getCommonInitialState(),
  trainedFileInfo: getCommonInitialState(),
  trainLLMCancel: getCommonInitialState(),
  startLLMService: getCommonInitialState(),
  stopLLMService: getCommonInitialState(),
  deleteFile: getCommonInitialState(),
  qna: getCommonInitialState(),
  contextList: getCommonInitialState(),
  contextData: getCommonInitialState(),
  deleteContext: getCommonInitialState(),
  selectedContext: null,
  selectedFile: null,
  chats: [],
  userId: null,
};

const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {

    //set Sidebar
    toggleSidebar: (state, action) => { state.sidebar = !state.sidebar },

    // service status
    serviceStatusStart: (state, action) => { state.serviceStatus = getCommonStart() },
    serviceStatusSuccess: (state, action) => { state.serviceStatus = getCommonSuccess(action.payload) },
    serviceStatusFailure: (state, action) => { state.serviceStatus = getCommonFailure(action.payload) },

    //upload file
    uploadFileStart: (state, action) => { state.uploadFile = getCommonStart() },
    uploadFileSuccess: (state, action) => { state.uploadFile = getCommonSuccess(action.payload) },
    uploadFileFailure: (state, action) => { state.uploadFile = getCommonFailure(action.payload) },

    //train LLM
    trainLLMStart: (state, action) => { state.trainLLM = getCommonStart() },
    trainLLMSuccess: (state, action) => { state.trainLLM = getCommonSuccess(action.payload) },
    trainLLMFailure: (state, action) => { state.trainLLM = getCommonFailure(action.payload) },

    //get trained file info
    trainedFileInfoStart: (state, action) => { state.trainedFileInfo = getCommonStart() },
    trainedFileInfoSuccess: (state, action) => { state.trainedFileInfo = getCommonSuccess(action.payload) },
    trainedFileInfoFailure: (state, action) => { state.trainedFileInfo = getCommonFailure(action.payload) },

    //cancel train LLM
    trainLLMCancelStart: (state, action) => { state.trainLLMCancel = getCommonStart() },
    trainLLMCancelSuccess: (state, action) => { state.trainLLMCancel = getCommonSuccess(action.payload) },
    trainLLMCancelFailure: (state, action) => { state.trainLLMCancel = getCommonFailure(action.payload) },

    //start LLM service
    startLLMServiceStart: (state, action) => { state.startLLMService = getCommonStart() },
    startLLMServiceSuccess: (state, action) => { state.startLLMService = getCommonSuccess(action.payload) },
    startLLMServiceFailure: (state, action) => { state.startLLMService = getCommonFailure(action.payload) },

    //stop LLM service
    stopLLMServiceStart: (state, action) => { state.stopLLMService = getCommonStart() },
    stopLLMServiceSuccess: (state, action) => { state.stopLLMService = getCommonSuccess(action.payload) },
    stopLLMServiceFailure: (state, action) => { state.stopLLMService = getCommonFailure(action.payload) },

    //delete file
    deleteFileStart: (state, action) => { state.deleteFile = getCommonStart(action.payload) },
    deleteFileSuccess: (state, action) => { state.deleteFile = getCommonSuccess(action.payload) },
    deleteFileFailure: (state, action) => { state.deleteFile = getCommonFailure(action.payload) },

    //qna
    qnaStart: (state, action) => { state.qna = getCommonStart(action.payload) },
    qnaSuccess: (state, action) => { state.qna = getCommonSuccess(action.payload) },
    qnaFailure: (state, action) => { state.qna = getCommonFailure(action.payload) },

    //context list
    contextListStart: (state, action) => { state.contextList = getCommonStart() },
    contextListSuccess: (state, action) => { state.contextList = getCommonSuccess(action.payload) },
    contextListFailure: (state, action) => { state.contextList = getCommonFailure(action.payload) },

    //context data
    contextDataStart: (state, action) => { state.contextData = getCommonStart() },
    contextDataSuccess: (state, action) => { state.contextData = getCommonSuccess(action.payload) },
    contextDataFailure: (state, action) => { state.contextData = getCommonFailure(action.payload) },

    //delete context
    deleteContextStart: (state, action) => { state.deleteContext = getCommonStart(action.payload) },
    deleteContextSuccess: (state, action) => { state.deleteContext = getCommonSuccess(action.payload) },
    deleteContextFailure: (state, action) => { state.deleteContext = getCommonFailure(action.payload) },

    //set selected context
    setSelectedContext: (state, action) => { state.selectedContext = action.payload },

    //set chats
    setChats: (state, action) => { state.chats = action.payload },

    // set selected file
    setSelectedFile: (state, action) => { state.selectedFile = action.payload }, 

    //add query to context
    addQueryToContext: (state, {payload}) => { 
      state.chats = [...state.chats, {
        "content": payload.query,
        "role": payload.role,
      }]
    },
    
    // set user id
    setUserID: (state, action) => { state.userId = action.payload }, 

  },
});

export const {
  toggleSidebar,
  settingsJsonStart,
  serviceStatusFailure,
  serviceStatusStart,
  serviceStatusSuccess,
  uploadFileStart,
  uploadFileSuccess,
  uploadFileFailure,
  trainLLMStart,
  trainLLMSuccess,
  trainLLMFailure,
  trainedFileInfoStart,
  trainedFileInfoSuccess,
  trainedFileInfoFailure,
  trainLLMCancelStart,
  trainLLMCancelSuccess,
  trainLLMCancelFailure,
  startLLMServiceStart,
  startLLMServiceSuccess,
  startLLMServiceFailure,
  stopLLMServiceStart,
  stopLLMServiceSuccess,
  stopLLMServiceFailure,
  deleteFileStart,
  deleteFileSuccess,
  deleteFileFailure,
  qnaStart,
  qnaSuccess,
  qnaFailure,
  contextListStart,
  contextListSuccess,
  contextListFailure,
  contextDataStart,
  contextDataSuccess,
  contextDataFailure,
  deleteContextStart,
  deleteContextSuccess,
  deleteContextFailure,
  setSelectedContext,
  setUploadedFiles,
  addQueryToContext,
  setChats,
  setSelectedFile,
  setUserID
} = CommonSlice.actions;

export default CommonSlice.reducer;
