import React, { useEffect, useState } from "react";
import {
  Image,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import EmPowerModal from "../../DashBoard/EmPowerModal";
import ReactJoyride from "react-joyride";

const Sidebar = (props) => {
  const location = useLocation();
  const [emPower, setEmPower] = useState(false);
  const [status, setStatus] = useState(0);
  const [skipRender, setSkipRender] = useState(true);
  const serviceStatus = useSelector((state) => state.common.serviceStatus);

  const closeEmPowerModal = () => {
    setEmPower(false);
  };

  const Step1 = () => {
    return (
      <div className="tour-step-1">
        <Image
          src={window.location.origin + "/img/tour/upload-file-tour-img.png"}
          className="tour-step-img"
        />
      </div>
    );
  };

  const Step2 = () => {
    return (
      <div className="tour-step-2">
        <Image
          src={window.location.origin + "/img/tour/manage-file-tour-img.png"}
          className="tour-step-img"
        />
      </div>
    );
  };

  const Step3 = () => {
    return (
      <div className="tour-step-3">
        <Image
          src={window.location.origin + "/img/tour/prompt-tour-img.png"}
          className="tour-step-img"
        />
      </div>
    );
  };

  const Step4 = () => {
    return (
      <div className="tour-step-4">
        <Image
          src={window.location.origin + "/img/tour/empower-tour-img.png"}
          className="tour-step-img"
        />
      </div>
    );
  };

  const steps = [
    {
      target: ".my-first-step",
      title: "Upload Files",
      content: <Step1 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-second-step",
      title: "Manage Files",
      content: <Step2 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-third-step",
      title: "Begin with Prompt!",
      content: <Step3 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-fourth-step",
      title: "Empower Service",
      content: <Step4 />,
      disableBeacon: true,
      placement: "top",
    },
  ];

  const handleJoyrideCallback = (data) => {
    if (["next", "skip"].includes(data.action)) {
      if (
        (["skip"].includes(data.action) && data.lifecycle == "complete") ||
        (["next"].includes(data.action) && data.lifecycle == "init")
      ) {
        localStorage.setItem("dashboard_tour", 1);
      }
    }
  };

  useEffect(() => {
    if (!serviceStatus.buttonDisable && Object.keys(serviceStatus.data).length > 0) {
      setStatus(serviceStatus.data.status == "API is working" ? 1 : 0);
    }
    setSkipRender(false);
  }, [serviceStatus]);

  return (
    <>
      <div className={`admin-ui-sidebar`}>
        <div className="admin-ui-sidebar-collapse">
          <div className="admin-ui-collapse-logo-sec">
            <Link className="" to="/">
              <Image
                className="admin-ui-collapse-logo"
                src={window.location.origin + "/img/file-refiner-logo.png"}
              />
            </Link>
          </div>
          {localStorage.getItem("dashboard_tour") != 1 ? (
            <ReactJoyride
              // floaterProps={{ placement: "right" }}
              steps={steps}
              run={true}
              continuous={true}
              spotlightClicks={true}
              scrollToFirstStep={true}
              disableScrolling={true}
              showSkipButton
              showProgress={true}
              disableOverlayClose={true}
              callback={handleJoyrideCallback}
              hideCloseButton={true}
              autoStart
              className="joyride-dashboard"
              styles={{
                options: {
                  borderRadius: "0",
                  arrowColor: "#fff",
                  backgroundColor: "#fff",
                  overlayColor: "#312f2f",
                  primaryColor: "#000",
                  textColor: "#195097",
                  width: 550,
                  zIndex: 99999,
                },
                buttonNext: {
                  fontSize: "0.9em",
                  padding: "1em 1em",
                  display: "inline-block",
                  backgroundColor: "#195097",
                  border: "0",
                  letterSpacing: ".5px",
                  borderRadius: "0!important",
                  fontWeight: "500",
                  marginLeft: "16px",
                  outline: "none!important",
                  position: "relative",
                },
                overlay: {
                  backgroundColor: "none",
                  mixBlendMode: "unset",
                },
                spotlight: {
                  backgroundColor: "#FFFFFF66",
                  borderRadius: 0,
                  boxShadow: "0px 0px 0px 9999px rgba(0,0,0,0.7)",
                  marginTop: "10px",
                  marginLeft: "0px",
                  maxHeight: "59px",
                  maxWidth: "4.8em",
                },
              }}
              {...props}
            >
              Test
            </ReactJoyride>
          ) : null}
          <div className="admin-ui-collapse-body">
            <ul className="admin-ui-sidebar-list list-unstyled">
              <li>
                <Link
                  to="/upload"
                  className={`my-first-step ${
                    location.pathname == "/upload" && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 15v4c0 2.757-2.243 5-5 5H5c-2.757 0-5-2.243-5-5v-4c0-1.654 1.346-3 3-3h3c1.103 0 2 .897 2 2s.897 2 2 2h4c1.103 0 2-.897 2-2s.897-2 2-2h3c1.654 0 3 1.346 3 3zM8.707 5.293L11 3v7a1 1 0 102 0V3l2.293 2.293a.997.997 0 001.414 0 .999.999 0 000-1.414L13.414.586a1.99 1.99 0 00-1.4-.583L12 0l-.014.003a1.989 1.989 0 00-1.4.583L7.293 3.879a.999.999 0 101.414 1.414z"></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="/file-uploading"
                  className={`my-second-step ${
                    location.pathname == "/file-uploading" && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19.5 24a1 1 0 01-.929-.628l-.844-2.113-2.116-.891a1.007 1.007 0 01.035-1.857l2.088-.791.837-2.092a1.008 1.008 0 011.858 0l.841 2.1 2.1.841a1.007 1.007 0 010 1.858l-2.1.841-.841 2.1A1 1 0 0119.5 24zM10 21a2 2 0 01-1.936-1.413L6.45 14.54l-5.063-1.694a2.032 2.032 0 01.052-3.871l5.023-1.534 1.692-5.054A1.956 1.956 0 0110.108 1a2 2 0 011.917 1.439l1.532 5.015 5.03 1.61a2.042 2.042 0 010 3.872l-5.039 1.612-1.612 5.039A2 2 0 0110 21zM20.5 7a1 1 0 01-.97-.757l-.357-1.43-1.433-.385a1 1 0 01.034-1.94l1.4-.325.356-1.406a1 1 0 011.94 0l.354 1.418 1.418.355a1 1 0 010 1.94l-1.418.355-.354 1.418A1 1 0 0120.5 7z"></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="/chat"
                  className={`my-third-step ${
                    location.pathname == "/chat" && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M14 7V.46a6.977 6.977 0 012.465 1.59l3.484 3.486A6.954 6.954 0 0121.54 8H15a1 1 0 01-1-1zm8 3.485V19a5.006 5.006 0 01-5 5H7a5.006 5.006 0 01-5-5V5a5.006 5.006 0 015-5h4.515c.163 0 .324.013.485.024V7a3 3 0 003 3h6.976c.011.161.024.322.024.485zM14 19a1 1 0 00-1-1H8a1 1 0 000 2h5a1 1 0 001-1zm3-4a1 1 0 00-1-1H8a1 1 0 000 2h8a1 1 0 001-1z"></path>
                  </svg>
                </Link>
              </li>
            </ul>
            <ul className="admin-ui-sidebar-list empower-icon list-unstyled">
              <li>
                <Link
                  onClick={() => setEmPower(true)}
                  type="button"
                  className={`my-fourth-step ${status ? "status-active" : ""}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 512.292 512.292"
                    version="1.1"
                    viewBox="0 0 512.292 512.292"
                    xmlSpace="preserve"
                  >
                    <path d="M256.061 0c17.673 0 32 14.327 32 32v106.667c0 17.673-14.327 32-32 32-17.673 0-32-14.327-32-32V32c0-17.673 14.326-32 32-32z"></path>
                    <path d="M330.727 105.387a30.998 30.998 0 0014.315 26.581c80.432 49.143 105.796 154.185 56.652 234.616S247.51 472.38 167.078 423.237 61.282 269.052 110.426 188.62a170.676 170.676 0 0156.269-56.418 31.384 31.384 0 0014.699-26.795c.049-17.673-14.238-32.039-31.911-32.088a31.994 31.994 0 00-17.155 4.931C22.233 146.634-11.58 291.318 56.803 401.412s213.067 143.907 323.161 75.524 143.907-213.067 75.524-323.161a234.675 234.675 0 00-75.524-75.524c-14.997-9.461-34.824-4.973-44.285 10.024a32.106 32.106 0 00-4.952 17.112z"></path>
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <EmPowerModal
        emPower={emPower}
        closeEmPowerModal={closeEmPowerModal}
        setEmPower={setEmPower}
      />
    </>
  );
};

export default Sidebar;
