import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  InputGroup,
  Row,
  Col,
  Button,
  Dropdown,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addQueryToContext,
  contextDataStart,
  contextListStart,
  contextListSuccess,
  deleteContextStart,
  qnaStart,
  setChats,
  setSelectedContext,
  setSelectedFile,
} from "../../store/slices/CommonSlice";
import ChatMobileMainWrapper from "./ChatMobileMainWrapper";
import RenameThreadModal from "./RenameThreadModal";
import NotChatFound from "../Helper/NoChatFound";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";

const ChatIndex = (props) => {
  const dispatch = useDispatch();

  const [renameThread, setRenameThread] = useState(false);
  const contextList = useSelector((state) => state.common.contextList);
  const selectedContext = useSelector((state) => state.common.selectedContext);
  const contextData = useSelector((state) => state.common.contextData);
  const [singleMsg, setSingleMsg] = useState("");
  const qna = useSelector((state) => state.common.qna);
  const [skipRender, setSkipRender] = useState(false);
  const chats = useSelector((state) => state.common.chats);
  const [chatList, setChatList] = useState([]);
  const deleteContext = useSelector((state) => state.common.deleteContext);
  const selectedFile = useSelector((state) => state.common.selectedFile);
  const userId = useSelector((state) => state.common.userId);
  const containerRef = useRef(null);
  const lastMsg = useRef();

  const [scrollDown, setScrollDown] = useState({
    notify: false,
    msg_count: 0,
    is_at_bottom: true,
  });

  const EightDigitContext = () => {
    return Math.floor(10000000 + Math.random() * 90000000);
  }

  const closeRenameThreadModal = () => {
    setRenameThread(false);
  };

  useEffect(() => {
    if(selectedFile) {
      let findContext = contextList.data?.data?.context_id.find(item => item.file_name == selectedFile);
      if(findContext){
        dispatch(setSelectedContext(findContext.id));
      } else {
        handleNewThread(selectedFile);
      }
    } else {
      dispatch(
        contextListStart({
          bot_id: userId,
          user_id: userId,
        })
      );
    }
    return () => {
      dispatch(setSelectedFile(null));
    };
  }, []);

  useEffect(() => {
    if (!contextList.buttonDisable && Object.keys(contextList.data).length > 0 && contextList.data.data.context_id.length > 0) {
      let chats = contextList.data.data.context_id.map((item, index) => ({
        id: item.id,
        title: item.title,
      }));
      setChatList(chats.reverse());
      !selectedFile && dispatch(setSelectedContext(contextList.data.data.context_id[chats.length - 1].id));
    }
  }, [contextList]);

  useEffect(() => {
    setSingleMsg("");
    selectedContext && dispatch(
      contextDataStart({
        bot_id: userId,
        user_id: userId,
        uuid: selectedContext,
      })
    );
  }, [selectedContext]);

  useEffect(() => {
    if (!skipRender && !contextData.buttonDisable && (Object.keys(contextData.data).length > 0)) {
      dispatch(setChats(contextData.data.data ? contextData.data.data.data.history : []));
    }
    else
    {
      dispatch(setChats([]));
    }
    setSkipRender(false);
  }, [contextData]);

  const onSendQuery = (e) => {
    e.preventDefault();
    if(singleMsg){
      dispatch(addQueryToContext({ query: singleMsg, role: "user" }));
      dispatch(
        qnaStart({
          bot_id: userId,
          user_id: userId,
          prompt:
            "You are an intelligent bot that provides answers based on the context you receive.",
          context_id: selectedContext,
          query: singleMsg,
          file_name: contextList.data?.data?.context_id?.filter(item => item.id == selectedContext)[0].file_name || "all",
        })
      );
      setSingleMsg("");
    } 
  };

  useEffect(() => {
    if (!skipRender && !qna.buttonDisable && Object.keys(qna.data).length > 0) {
      dispatch(addQueryToContext({ query: qna.data.response, role: "assistant" }));
    }
    setSkipRender(false);
  }, [qna]);

  const handleNewThread = (file_name) => {
    let context_id = EightDigitContext();
    dispatch(contextListSuccess({
      ...contextList.data,
      data: {
        ...contextList.data.data,
        context_id: [...contextList.data.data.context_id, {
          id: context_id,
          title: "New Thread",
          file_name: file_name,
        }]
      }
    }));
    dispatch(setSelectedContext(context_id));
    dispatch(setChats([]));
  }

  useEffect(() => {
    if (!skipRender && !deleteContext.loading && Object.keys(deleteContext.data).length > 0) {
      dispatch(contextListStart({
        bot_id: userId,
        user_id: userId,
      }));
    }
    setSkipRender(false);
  }, [deleteContext]);

  return (
    <div className="admin-ui-body-sec">
      <div className="chat-layout-sec">
        <Row className="g-0">
          <Col md={3}>
            <div className="chat-threads-sidebar-sec">
              <div className="chat-threads-header-sec">
                <h3>Threads</h3>
                <Button className="new-threads-btn" onClick={() => handleNewThread("all")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 20 18"
                  >
                    <g clipPath="url(#clip0_117_1767)">
                      <path
                        fill="#195097"
                        fillRule="evenodd"
                        d="M10.8 1.338H4.333c-1.149 0-2.251.47-3.064 1.306A4.526 4.526 0 000 5.797V16.5a.913.913 0 00.254.63.854.854 0 00.613.261H13c1.15 0 2.251-.47 3.064-1.306a4.526 4.526 0 001.27-3.153V9.365c-3.682 0-6.667-2.995-6.667-6.69 0-.457.046-.905.133-1.337zM5.49 7.806a.854.854 0 01.576-.225c.23 0 .45.094.614.261a.907.907 0 01.254.63v1.784l-.007.105a.899.899 0 01-.304.578.85.85 0 01-1.187-.071.907.907 0 01-.236-.612V8.473l.006-.105a.899.899 0 01.285-.562zm5.164.036a.845.845 0 011.226 0 .904.904 0 01.254.63v1.784a.904.904 0 01-.254.631.851.851 0 01-1.226 0 .907.907 0 01-.254-.63V8.472c0-.237.091-.464.254-.63z"
                        clipRule="evenodd"
                      ></path>
                      <path
                        stroke="#195097"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13.913 3.545h2.667m0 0h2.666m-2.666 0V.87m0 2.675v2.676"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_117_1767">
                        <path fill="#fff" d="M0 0H20V17.391H0z"></path>
                      </clipPath>
                    </defs>
                  </svg>
                  <span>New Thread</span>
                </Button>
              </div>
              <div className="chat-threads-body-sec">
                <ul className="list-unstyled chat-threads-list-sec">
                  {contextList.loading ? (
                    <Skeleton count={5} height={35} />
                  ) : (chatList.length > 0) ? (
                    chatList.map((item, index) => (
                      <li
                        className={`${selectedContext == item.id && "active"}`}
                        onClick={() => dispatch(setSelectedContext(item.id))}
                        key={index}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "0.5em",
                            alignItems: "center",
                          }}
                        >
                          <div className="chat-threads-list-icon-sec">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 15 15"
                            >
                              <path
                                fill="#DDE5E5"
                                fillRule="evenodd"
                                d="M11.25.5c.995 0 1.948.41 2.652 1.139A3.963 3.963 0 0115 4.389v6.222c0 1.031-.395 2.02-1.098 2.75A3.683 3.683 0 0111.25 14.5H.75a.737.737 0 01-.53-.228.793.793 0 01-.22-.55V4.39c0-1.032.395-2.02 1.098-2.75A3.683 3.683 0 013.75.5h7.5zm-6 5.444a.735.735 0 00-.498.197.787.787 0 00-.247.49l-.005.091v1.556c0 .198.073.389.205.533a.733.733 0 001.027.063.786.786 0 00.263-.505L6 8.278V6.722a.793.793 0 00-.22-.55.737.737 0 00-.53-.228zm4.5 0a.737.737 0 00-.53.228.793.793 0 00-.22.55v1.556c0 .206.079.404.22.55.14.146.331.228.53.228s.39-.082.53-.228a.792.792 0 00.22-.55V6.722a.792.792 0 00-.22-.55.737.737 0 00-.53-.228z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                          <div className="chat-threads-list-title-sec">
                            {item.title}
                          </div>
                        </div>
                        <Dropdown className="chat-threads-list-dropdown-sec">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon-md"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {/* <Dropdown.Item
                          type="button"
                          onClick={() => setRenameThread(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#9DA3AE"
                              fillRule="evenodd"
                              d="M3.25 22a.75.75 0 01.75-.75h16a.75.75 0 010 1.5H4a.75.75 0 01-.75-.75z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#9DA3AE"
                              d="M11.52 14.929l5.917-5.917a8.232 8.232 0 01-2.661-1.787 8.232 8.232 0 01-1.788-2.662L7.07 10.48c-.462.462-.693.692-.891.947a5.24 5.24 0 00-.599.969c-.139.291-.242.601-.449 1.22l-1.088 3.267a.848.848 0 001.073 1.073l3.266-1.088c.62-.207.93-.31 1.221-.45.344-.163.669-.364.969-.598.255-.199.485-.43.947-.891zM19.079 7.37a3.146 3.146 0 00-4.45-4.449l-.71.71.031.09c.26.749.751 1.732 1.674 2.655A7.003 7.003 0 0018.37 8.08l.71-.71z"
                            ></path>
                          </svg>
                          <span>Edit</span>
                        </Dropdown.Item> */}
                            <Dropdown.Item className="dropdown-delete-item" onClick={()=> contextList.data.data.context_id.find(thread => thread.id == item.id) ? dispatch(deleteContextStart({ uuid: selectedContext })) : setChatList(chatList.filter(thread => thread.id !== item.id))}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#FF6A6A"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill=""
                                  d="M3 6.524c0-.395.327-.714.73-.714h4.788c.006-.842.098-1.995.932-2.793A3.68 3.68 0 0112 2a3.68 3.68 0 012.55 1.017c.834.798.926 1.951.932 2.793h4.788c.403 0 .73.32.73.714a.722.722 0 01-.73.714H3.73A.722.722 0 013 6.524z"
                                ></path>
                                <path
                                  fill="#FF6A6A"
                                  fillRule="evenodd"
                                  d="M11.596 22h.808c2.783 0 4.174 0 5.08-.886.904-.886.996-2.34 1.181-5.246l.267-4.187c.1-1.577.15-2.366-.303-2.866-.454-.5-1.22-.5-2.753-.5H8.124c-1.533 0-2.3 0-2.753.5-.454.5-.404 1.289-.303 2.866l.267 4.188c.185 2.906.277 4.36 1.182 5.245.905.886 2.296.886 5.079.886zm-1.35-9.812c-.04-.433-.408-.75-.82-.707-.413.044-.713.43-.672.865l.5 5.263c.04.434.408.75.82.707.413-.044.713-.43.672-.864l-.5-5.264zm4.329-.707c.412.044.713.43.671.865l-.5 5.263c-.04.434-.409.75-.82.707-.413-.044-.713-.43-.672-.864l.5-5.264c.04-.433.409-.75.82-.707z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                              <span>Delete</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    ))
                  ) : (
                    <NoDataFound />
                  )}
                </ul>
              </div>
            </div>
          </Col>
          <Col md={9}>
            <div className="chat-main-wrapper-sec mobile-hide">
              <div className="chat-main-wrapper-header-sec">
                <h3 className="mb-3">{contextData.buttonDisable ? <Skeleton width={200} height={30}/> : (selectedContext && chatList.length > 0) ? chatList.filter(item => item.id == selectedContext).length > 0 ?  chatList.filter(item => item.id == selectedContext)[0].title : "" : "" }</h3>    
                {/* <Form className="chat-search-form-sec">
                  <div className="chat-search-header-card">
                    <InputGroup className="mb-0">
                      <Form.Control placeholder="Type a command or search..." />
                      <InputGroup.Text id="basic-addon2">
                        <Button type="button" className="search-btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 18 18"
                          >
                            <path
                              fill="#03363D"
                              stroke="#03363D"
                              d="M16.806 15.873l-4.208-4.207A6.532 6.532 0 007.374 1.002a6.537 6.537 0 00-6.372 6.362 6.532 6.532 0 006.033 6.684 6.537 6.537 0 004.628-1.448l4.208 4.206a.662.662 0 00.935-.935v.002zM2.353 7.549A5.19 5.19 0 018.56 2.46a5.193 5.193 0 013.784 7.078 5.191 5.191 0 01-8.469 1.682 5.195 5.195 0 01-1.522-3.67z"
                            ></path>
                          </svg>
                        </Button>
                      </InputGroup.Text>
                    </InputGroup>
                    <Button className="chat-clear-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        x="0"
                        y="0"
                        enableBackground="new 0 0 500.696 500.696"
                        version="1.1"
                        viewBox="0 0 500.696 500.696"
                        xmlSpace="preserve"
                      >
                        <path d="M121.232 158.288l-44.245 12.971a107.438 107.438 0 00-66.411 56.213 106.095 106.095 0 00-2.923 85.739c.149.384.341.747.491 1.131l36.693-5.995a204.793 204.793 0 0091.733-51.925c8.424-8.247 21.939-8.104 30.187.32 8.247 8.424 8.104 21.939-.32 30.187a248.514 248.514 0 01-112.96 63.168l-25.003 4.139a272.556 272.556 0 0052.907 62.507l43.797-16.256 29.867-14.933a203.613 203.613 0 0076.608-66.944l11.072-16.235c6.366-9.914 19.563-12.791 29.478-6.426 9.914 6.366 12.791 19.563 6.426 29.478-.211.329-.432.652-.661.969l-11.051 16.235a246.085 246.085 0 01-92.736 81.067l-33.067 16.491-17.984 6.656a282.152 282.152 0 00142.805 38.4h19.712a21.566 21.566 0 0012.331-3.883 352.134 352.134 0 00109.291-119.168L174.906 134.053a270.07 270.07 0 01-53.674 24.235zM494.373 21.755c-8.433-8.403-22.074-8.403-30.507 0L362.234 123.387l-6.827-6.677c-37.156-36.291-95.028-40.603-137.152-10.219-1.963 1.408-3.904 2.581-5.888 3.947l213.909 210.005a303.48 303.48 0 0014.933-56.875 107.286 107.286 0 00-30.997-93.312l-17.067-16.725 101.271-101.27c8.391-8.444 8.372-22.085-.043-30.506z"></path>
                      </svg>
                    </Button>
                  </div>
                </Form> */}
              </div>
              <div className="chat-main-wrapper-body-sec" ref={containerRef}>
                {contextData.buttonDisable ? (
                  <Skeleton count={5} height={30} className="mb-3" />
                ) : chats.length > 0 ?
                <>
                  {chats.map((item, index) => (
                      <React.Fragment key={index}>
                        {item.role == "user" ? (
                          <div className="chat-main-sender-sec">
                            <div className="chat-main-sender-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#195097"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 512 512"
                                version="1.1"
                                viewBox="0 0 512 512"
                                xmlSpace="preserve"
                              >
                                <circle cx="256" cy="128" r="128"></circle>
                                <path d="M256 298.667c-105.99.118-191.882 86.01-192 192C64 502.449 73.551 512 85.333 512h341.333c11.782 0 21.333-9.551 21.333-21.333-.117-105.99-86.009-191.883-191.999-192z"></path>
                              </svg>
                            </div>
                            <div className="chat-main-sender-message-sec">
                              <div className="chat-main-sender-message">
                                {item.content}
                              </div>
                              {/* {<Button className="chat-main-sender-edit-btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#9DA3AE"
                              fillRule="evenodd"
                              d="M3.25 22a.75.75 0 01.75-.75h16a.75.75 0 010 1.5H4a.75.75 0 01-.75-.75z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#9DA3AE"
                              d="M11.52 14.929l5.917-5.917a8.232 8.232 0 01-2.661-1.787 8.232 8.232 0 01-1.788-2.662L7.07 10.48c-.462.462-.693.692-.891.947a5.24 5.24 0 00-.599.969c-.139.291-.242.601-.449 1.22l-1.088 3.267a.848.848 0 001.073 1.073l3.266-1.088c.62-.207.93-.31 1.221-.45.344-.163.669-.364.969-.598.255-.199.485-.43.947-.891zM19.079 7.37a3.146 3.146 0 00-4.45-4.449l-.71.71.031.09c.26.749.751 1.732 1.674 2.655A7.003 7.003 0 0018.37 8.08l.71-.71z"
                            ></path>
                          </svg>
                        </Button>} */}
                            </div>
                          </div>
                        ) : (
                          <div className="chat-main-receiver-sec">
                            <div className="chat-main-receiver-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#fff"
                                width="20"
                                height="20"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M19.5 24a1 1 0 01-.929-.628l-.844-2.113-2.116-.891a1.007 1.007 0 01.035-1.857l2.088-.791.837-2.092a1.008 1.008 0 011.858 0l.841 2.1 2.1.841a1.007 1.007 0 010 1.858l-2.1.841-.841 2.1A1 1 0 0119.5 24zM10 21a2 2 0 01-1.936-1.413L6.45 14.54l-5.063-1.694a2.032 2.032 0 01.052-3.871l5.023-1.534 1.692-5.054A1.956 1.956 0 0110.108 1a2 2 0 011.917 1.439l1.532 5.015 5.03 1.61a2.042 2.042 0 010 3.872l-5.039 1.612-1.612 5.039A2 2 0 0110 21zM20.5 7a1 1 0 01-.97-.757l-.357-1.43-1.433-.385a1 1 0 01.034-1.94l1.4-.325.356-1.406a1 1 0 011.94 0l.354 1.418 1.418.355a1 1 0 010 1.94l-1.418.355-.354 1.418A1 1 0 0120.5 7z"></path>
                              </svg>
                            </div>
                            <div className="chat-main-receiver-message-sec">
                              <div className="chat-main-receiver-message">
                                {item.content}
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                  ))}
                  <div ref={lastMsg} />
                </>
                  : (
                  <NotChatFound />
                )}
                {/* {scrollDown.is_at_bottom === false && (
                  <div className="scroll-down-button" onClick={handleScrollDown}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#03363d"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12.836.029C9.367-.206 5.961 1.065 3.509 3.521S-.211 9.378.03 12.854C.47 19.208 6.082 24 13.083 24H19c2.757 0 5-2.243 5-5v-6.66C24 5.861 19.097.454 12.836.029zm3.871 14.56l-2.535 2.535a2.992 2.992 0 01-2.122.877H12a2.982 2.982 0 01-2.071-.877l-2.636-2.636a.999.999 0 111.414-1.414L11 15.367V7a1 1 0 012 0v8.467l2.293-2.293a.999.999 0 111.414 1.414z"></path>
                    </svg>
                  </div>
                )} */}
              </div>
              <div className="chat-main-wrapper-footer-sec">
                <Form
                  onSubmit={(e) => onSendQuery(e)}
                  className="chat-main-wrapper-typing-form"
                >
                  <div className="chat-main-wrapper-typing-box">
                    <div className="chat-main-wrapper-footer-input-sec">
                      <Form.Group
                        className="mb-0"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Ask or search anything..."
                          onChange={(e) => setSingleMsg(e.target.value)}
                          disabled={qna.buttonDisable}
                          value={singleMsg}
                        />
                      </Form.Group>
                    </div>
                    <div className="chat-main-wrapper-footer-submit-sec">
                      <Button
                        disabled={!singleMsg || qna.buttonDisable}
                        onClick={onSendQuery}
                        className="chat-main-wrapper-footer-submit-btn"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          fill="#fff"
                          height="20"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M.172 3.708A2.745 2.745 0 01.917.713 2.745 2.745 0 013.965.282l18.09 8.444A3.584 3.584 0 0124 10.999H4.048L.229 3.835a1.446 1.446 0 01-.057-.127zM4.062 13L.309 20.175a.944.944 0 00-.054.122 2.744 2.744 0 00.749 2.993 2.756 2.756 0 001.841.708c.409 0 .819-.092 1.201-.279l18.011-8.438a3.582 3.582 0 001.945-2.28H4.062z"></path>
                        </svg>
                        {qna.buttonDisable ? (
                          <span>Sending...</span>
                        ) : (
                          <span>Send</span>
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <ChatMobileMainWrapper />
          </Col>
        </Row>
      </div>
      <RenameThreadModal
        renameThread={renameThread}
        closeRenameThreadModal={closeRenameThreadModal}
        setRenameThread={setRenameThread}
      />
    </div>
  );
};

export default ChatIndex;
