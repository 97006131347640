import React, { useEffect, useState } from "react";
import HeaderIndex from "./Header/HeaderIndex";
import { useDispatch, useSelector } from "react-redux";
import SidebarIndex from "./Header/SidebarIndex";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { serviceStatusStart } from "../../store/slices/CommonSlice";

const MainLayout = (props) => {

  const sidebar = useSelector(state => state.common.sidebar);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(serviceStatusStart());
  }, []);

  useEffect(() => {
  }, [sidebar]);

  return (
    <>
      <div className="admin-ui-sec">
        <SidebarIndex />
        <div
          className={`admin-ui-main-wrapper ${
            sidebar ? "admin-collapse-main-wrapper" : ""
          }`}
        >
          <HeaderIndex />
          {props.children}
        </div>
      </div>
    </>
  );
};

export default MainLayout;
