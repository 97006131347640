import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, HashRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import EmptyLayout from "../layouts/EmptyLayout";
import MainLayout from "../layouts/MainLayout";
import { ToastContainer } from "react-toastify";
import DashBoardIndex from "../DashBoard/DashBoardIndex";
import UploadIndex from "../Upload/UploadIndex";
import FileUploadIndex from "../FileUploading/FileUploadIndex";
import ChatIndex from "../Chat/ChatIndex";
import Page404 from "../Helper/Page404";
import WelcomePageIndex from "../Welcome/WelcomePageIndex";
import { useDispatch } from "react-redux";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { setUserID } from "../../store/slices/CommonSlice";

const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");
  let authentication =
    userId && accessToken && userLoginStatus == "true" ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [configLoading, setConfigLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    async function generateUserId() {
      const fpPromise = await FingerprintJS.load();
      const fp = await fpPromise;
      const result = await fp.get();
      const userId = result.visitorId;
      dispatch(setUserID(userId));
    }
    generateUserId();
  }, []);

  return (
    <>
      <ToastContainer />
      {/* <Helmet>
				<title>{configuration.get("configData.site_name")}</title>
				<link
					rel="icon"
					type="image/png"
					href={configuration.get("configData.site_icon")}
				/>
				<script src={configuration.get("configData.head_scripts")}></script>
			</Helmet> */}
      <HashRouter>
        <Routes>
          <Route
            path={"/"}
            element={
              <AppRoute
                component={LandingPageIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/upload"}
            element={
              <AppRoute
                component={UploadIndex}
                layout={MainLayout}
              />
            }
          />
            <Route
            path={"/file-uploading"}
            element={
              <AppRoute
                component={FileUploadIndex}
                layout={MainLayout}
              />
            }
          />
           <Route
            path={"/chat"}
            element={
              <AppRoute
                component={ChatIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/welcome"}
            element={
              <AppRoute
                component={WelcomePageIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/*"}
            element={
              <AppRoute
                component={Page404}
                layout={MainLayout}
              />
            }
          />
        </Routes>
      </HashRouter>
    </>
  );
};

export default App;
