import React, { useEffect, useMemo, useState } from "react";
import { Image, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setUploadedFiles,
  trainLLMStart,
  uploadFileStart,
  uploadFileSuccess,
} from "../../store/slices/CommonSlice";
import { useDropzone } from "react-dropzone";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../Environment";

const UploadIndex = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadFile = useSelector((state) => state.common.uploadFile);
  const [files, setFiles] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const uploadedFiles = useSelector((state) => state.common.uploadedFiles);
  const userId = useSelector((state) => state.common.userId);

  const max_files = 10;
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        'text/txt': [".txt"],
        'text/csv': [".csv"],
        'text/markdown': [".md"],
        'application/pdf': ['.pdf'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      },
      maxFiles: 10,
      useFsAccessApi: false,
      onDrop: (acceptedFiles, rejectedFiles) => {
        let validFiles = acceptedFiles.filter((file) => {
          const ext = file.name.slice(file.name.lastIndexOf("."));
          return ext == ".md" ? "text/markdown" : getInputProps().accept.includes(ext) && file.type;
        });

        validFiles = validFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        if (
          files.length + acceptedFiles.length > max_files ||
          rejectedFiles.length > max_files
        )
          getErrorNotificationMessage(
            `${
              max_files == 1
                ? "Oops! You can upload only one file at a time. If you've already uploaded a file, please remove it before attempting to upload another one."
                : `You can select max ${max_files} files`
            }`
          );
        else if (validFiles.length > 0) {
          const newFiles = validFiles.filter(
            (newFile) =>
              !files.some((existingFile) => existingFile.name === newFile.name)
          );
          setFiles([...files, ...newFiles]);
          if (newFiles.length < validFiles.length)
            getErrorNotificationMessage("Cannot upload same files again");
        } else getErrorNotificationMessage("Invalid File Type");
        setShowMessage(false);
      },
      onDragEnter: () => {
        setShowMessage(true);
      },
      onDragLeave: () => {
        setShowMessage(false);
      },
    });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 1,
    borderRadius: 0,
    borderColor: "#03363d",
    borderStyle: "dashed",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#03363d",
  };

  const acceptStyle = {
    borderColor: "#03363d",
  };

  const rejectStyle = {
    borderColor: "#03363d",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const removeFile = (fileIndex) => {
    const updatedFiles = files.filter((_, index) => index !== fileIndex);
    setFiles(updatedFiles);
  };

  const handleSubmit = async () => {
    setLoading(true);
    var formdata = new FormData();
    files.forEach((file) => {
      formdata.append("files", file);
    });

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    let response = await fetch(
      apiUrl + `uploadfile?user_id=${userId}&bot_id=${userId}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => error);
    if (response.success == true) {
      getSuccessNotificationMessage(response.message);
      setFiles([]);
      navigate("/file-uploading");
    } else {
      getErrorNotificationMessage("Something went wrong. Please try again.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !uploadFile.buttonDisable &&
      Object.keys(uploadFile.data).length > 0
    ) {
      setFiles([]);
      dispatch(
        trainLLMStart({
          user_id: userId,
          bot_id: userId,
        })
      );
    }
    setSkipRender(false);
  }, [uploadFile]);

  return (
    <>
      <div className="admin-ui-body-sec upload-ui-body-sec">
        <div className="file-upload-option-sec">
          <Row className="justify-content-md-center">
            <Col md={12}>
              <div className="file-upload-option-box">
                <Row className="justify-content-md-center align-items-center">
                  <Col md={6}>
                    {files.length > 0 ? (
                      <div className="file-upload-option-card mb-3" style={{textAlign:"left"}}>
                        <h5>Selected Files</h5>
                        {files.map((file, index) => (
                          <div
                            className={`upload-preview-sec ${
                              file.size / 1024 >= 5120 ? "large-file" : ""
                            }`}
                            key={file.name}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 18 22"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.0297 5.76641C17.1703 5.90703 17.25 6.09687 17.25 6.29609V20.75C17.25 21.1648 16.9148 21.5 16.5 21.5H1.5C1.08516 21.5 0.75 21.1648 0.75 20.75V1.25C0.75 0.835156 1.08516 0.5 1.5 0.5H11.4539C11.6531 0.5 11.8453 0.579688 11.9859 0.720313L17.0297 5.76641ZM15.5203 6.64062L11.1094 2.22969V6.64062H15.5203ZM4.5 10.2969C4.45027 10.2969 4.40258 10.3166 4.36742 10.3518C4.33225 10.387 4.3125 10.4346 4.3125 10.4844V11.6094C4.3125 11.6591 4.33225 11.7068 4.36742 11.742C4.40258 11.7771 4.45027 11.7969 4.5 11.7969H13.5C13.5497 11.7969 13.5974 11.7771 13.6326 11.742C13.6677 11.7068 13.6875 11.6591 13.6875 11.6094V10.4844C13.6875 10.4346 13.6677 10.387 13.6326 10.3518C13.5974 10.3166 13.5497 10.2969 13.5 10.2969H4.5ZM4.5 13.4844C4.45027 13.4844 4.40258 13.5041 4.36742 13.5393C4.33225 13.5745 4.3125 13.6221 4.3125 13.6719V14.7969C4.3125 14.8466 4.33225 14.8943 4.36742 14.9295C4.40258 14.9646 4.45027 14.9844 4.5 14.9844H8.8125C8.86223 14.9844 8.90992 14.9646 8.94508 14.9295C8.98025 14.8943 9 14.8466 9 14.7969V13.6719C9 13.6221 8.98025 13.5745 8.94508 13.5393C8.90992 13.5041 8.86223 13.4844 8.8125 13.4844H4.5Z"
                                fill={
                                  file.size / 1024 < 5120
                                    ? "#195097"
                                    : "#e00b0b"
                                }
                              />
                            </svg>
                            <div className="w-100">
                              <p className="file-upload-name">{file.name}</p>
                              <span className="file-upload-size">
                                {file.size >= 1024
                                  ? (file.size / (1024 * 1024)).toFixed(2) +
                                    "MB"
                                  : (file.size / 1024).toFixed(2) + " KB"}
                              </span>
                              {file.size / 1024 > 5120 && (
                                <p className="file-size-error">
                                  <strong>Error:</strong> File exceed more than{" "}
                                  {5120 / 1024} MB
                                </p>
                              )}
                            </div>
                            <div
                              onClick={() => {
                                removeFile(index);
                                const listFiles = files.filter(
                                  (_, i) => i !== index
                                );
                                // setFieldValue(
                                //   "file",
                                //   listFiles.length > 0 ? listFiles : null
                                // );
                              }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1ZM8 7.151L6.374 5.525C6.26142 5.41242 6.10872 5.34917 5.9495 5.34917C5.79028 5.34917 5.63758 5.41242 5.525 5.525C5.41242 5.63758 5.34917 5.79028 5.34917 5.9495C5.34917 6.10872 5.41242 6.26142 5.525 6.374L7.151 8L5.525 9.626C5.46925 9.68175 5.42503 9.74793 5.39486 9.82076C5.36469 9.8936 5.34917 9.97166 5.34917 10.0505C5.34917 10.1293 5.36469 10.2074 5.39486 10.2802C5.42503 10.3531 5.46925 10.4193 5.525 10.475C5.58075 10.5307 5.64693 10.575 5.71976 10.6051C5.7926 10.6353 5.87066 10.6508 5.9495 10.6508C6.02834 10.6508 6.1064 10.6353 6.17924 10.6051C6.25207 10.575 6.31825 10.5307 6.374 10.475L8 8.849L9.626 10.475C9.68175 10.5307 9.74793 10.575 9.82076 10.6051C9.8936 10.6353 9.97166 10.6508 10.0505 10.6508C10.1293 10.6508 10.2074 10.6353 10.2802 10.6051C10.3531 10.575 10.4193 10.5307 10.475 10.475C10.5307 10.4193 10.575 10.3531 10.6051 10.2802C10.6353 10.2074 10.6508 10.1293 10.6508 10.0505C10.6508 9.97166 10.6353 9.8936 10.6051 9.82076C10.575 9.74793 10.5307 9.68175 10.475 9.626L8.849 8L10.475 6.374C10.5307 6.31825 10.575 6.25207 10.6051 6.17924C10.6353 6.1064 10.6508 6.02834 10.6508 5.9495C10.6508 5.87066 10.6353 5.7926 10.6051 5.71976C10.575 5.64693 10.5307 5.58075 10.475 5.525C10.4193 5.46925 10.3531 5.42503 10.2802 5.39486C10.2074 5.36469 10.1293 5.34917 10.0505 5.34917C9.97166 5.34917 9.8936 5.36469 9.82076 5.39486C9.74793 5.42503 9.68175 5.46925 9.626 5.525L8 7.151Z"
                                  fill={
                                    file.size / 1024 < 5120
                                      ? "#195097"
                                      : "#e00b0b"
                                  }
                                />
                              </svg>
                            </div>
                          </div>
                        ))}
                        <Button
                          className="chat-main-wrapper-footer-submit-btn"
                          disabled={loading}
                          onClick={handleSubmit}
                          style={{justifyContent:"center"}}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            fill="#fff"
                            height="20"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M.172 3.708A2.745 2.745 0 01.917.713 2.745 2.745 0 013.965.282l18.09 8.444A3.584 3.584 0 0124 10.999H4.048L.229 3.835a1.446 1.446 0 01-.057-.127zM4.062 13L.309 20.175a.944.944 0 00-.054.122 2.744 2.744 0 00.749 2.993 2.756 2.756 0 001.841.708c.409 0 .819-.092 1.201-.279l18.011-8.438a3.582 3.582 0 001.945-2.28H4.062z"></path>
                          </svg>
                          {loading ? "Uploading..." : "Upload"}
                        </Button>
                      </div>
                    ) : null}
                    <div
                      {...getRootProps({
                        style,
                        onDrop: (e) => {},
                        onChange: (e) => {},
                      })}
                      className="file-upload-option-card"
                    >
                      <input {...getInputProps()} />
                      <div class="file-upload-option-icon-sec">
                        <Image
                          className="file-upload-option-icon"
                          src={
                            window.location.origin +
                            "/img/upload/upload-icon.svg"
                          }
                          type="image/png"
                        />
                      </div>
                      <h4>Drag & drop files here</h4>
                      <div className="separate-divider">
                        <span>Or</span>
                      </div>
                      <Button className="browse-file-btn">Browse Files</Button>
                      <div className="file-upload-options-supported-card">
                        <p>Supported Files</p>
                        <div className="file-upload-option-supported-format-box">
                          <div className="file-upload-option-supported-format-card">
                            TXT
                          </div>
                          <div className="file-upload-option-supported-format-card">
                            XLS
                          </div>
                          <div className="file-upload-option-supported-format-card">
                            PDF
                          </div>
                          <div className="file-upload-option-supported-format-card">
                            DOC
                          </div>
                          <div className="file-upload-option-supported-format-card">
                            MD
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    {/* <div className="file-upload-option-img-sec">
                    {files.length > 0 ? (
                        <>
                          <h5>Selected Files</h5>
                          {files.map((file, index) => (
                            <div
                              className={`upload-preview-sec ${
                                file.size / 1024 >= 5120 ? "large-file" : ""
                              }`}
                              key={file.name}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 18 22"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.0297 5.76641C17.1703 5.90703 17.25 6.09687 17.25 6.29609V20.75C17.25 21.1648 16.9148 21.5 16.5 21.5H1.5C1.08516 21.5 0.75 21.1648 0.75 20.75V1.25C0.75 0.835156 1.08516 0.5 1.5 0.5H11.4539C11.6531 0.5 11.8453 0.579688 11.9859 0.720313L17.0297 5.76641ZM15.5203 6.64062L11.1094 2.22969V6.64062H15.5203ZM4.5 10.2969C4.45027 10.2969 4.40258 10.3166 4.36742 10.3518C4.33225 10.387 4.3125 10.4346 4.3125 10.4844V11.6094C4.3125 11.6591 4.33225 11.7068 4.36742 11.742C4.40258 11.7771 4.45027 11.7969 4.5 11.7969H13.5C13.5497 11.7969 13.5974 11.7771 13.6326 11.742C13.6677 11.7068 13.6875 11.6591 13.6875 11.6094V10.4844C13.6875 10.4346 13.6677 10.387 13.6326 10.3518C13.5974 10.3166 13.5497 10.2969 13.5 10.2969H4.5ZM4.5 13.4844C4.45027 13.4844 4.40258 13.5041 4.36742 13.5393C4.33225 13.5745 4.3125 13.6221 4.3125 13.6719V14.7969C4.3125 14.8466 4.33225 14.8943 4.36742 14.9295C4.40258 14.9646 4.45027 14.9844 4.5 14.9844H8.8125C8.86223 14.9844 8.90992 14.9646 8.94508 14.9295C8.98025 14.8943 9 14.8466 9 14.7969V13.6719C9 13.6221 8.98025 13.5745 8.94508 13.5393C8.90992 13.5041 8.86223 13.4844 8.8125 13.4844H4.5Z"
                                  fill={
                                    file.size / 1024 < 5120
                                      ? "#195097"
                                      : "#e00b0b"
                                  }
                                />
                              </svg>
                              <div className="w-100">
                                <p className="file-upload-name">{file.name}</p>
                                <span className="file-upload-size">
                                  {file.size >= 1024
                                    ? (file.size / (1024 * 1024)).toFixed(2) +
                                      "MB"
                                    : (file.size / 1024).toFixed(2) + " KB"}
                                </span>
                                {file.size / 1024 > 5120 && (
                                  <p className="file-size-error">
                                    <strong>Error:</strong> File exceed more than{" "}
                                    {5120 / 1024} MB
                                  </p>
                                )}
                              </div>
                              <div
                                onClick={() => {
                                  removeFile(index);
                                  const listFiles = files.filter(
                                    (_, i) => i !== index
                                  );
                                  // setFieldValue(
                                  //   "file",
                                  //   listFiles.length > 0 ? listFiles : null
                                  // );
                                }}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1ZM8 7.151L6.374 5.525C6.26142 5.41242 6.10872 5.34917 5.9495 5.34917C5.79028 5.34917 5.63758 5.41242 5.525 5.525C5.41242 5.63758 5.34917 5.79028 5.34917 5.9495C5.34917 6.10872 5.41242 6.26142 5.525 6.374L7.151 8L5.525 9.626C5.46925 9.68175 5.42503 9.74793 5.39486 9.82076C5.36469 9.8936 5.34917 9.97166 5.34917 10.0505C5.34917 10.1293 5.36469 10.2074 5.39486 10.2802C5.42503 10.3531 5.46925 10.4193 5.525 10.475C5.58075 10.5307 5.64693 10.575 5.71976 10.6051C5.7926 10.6353 5.87066 10.6508 5.9495 10.6508C6.02834 10.6508 6.1064 10.6353 6.17924 10.6051C6.25207 10.575 6.31825 10.5307 6.374 10.475L8 8.849L9.626 10.475C9.68175 10.5307 9.74793 10.575 9.82076 10.6051C9.8936 10.6353 9.97166 10.6508 10.0505 10.6508C10.1293 10.6508 10.2074 10.6353 10.2802 10.6051C10.3531 10.575 10.4193 10.5307 10.475 10.475C10.5307 10.4193 10.575 10.3531 10.6051 10.2802C10.6353 10.2074 10.6508 10.1293 10.6508 10.0505C10.6508 9.97166 10.6353 9.8936 10.6051 9.82076C10.575 9.74793 10.5307 9.68175 10.475 9.626L8.849 8L10.475 6.374C10.5307 6.31825 10.575 6.25207 10.6051 6.17924C10.6353 6.1064 10.6508 6.02834 10.6508 5.9495C10.6508 5.87066 10.6353 5.7926 10.6051 5.71976C10.575 5.64693 10.5307 5.58075 10.475 5.525C10.4193 5.46925 10.3531 5.42503 10.2802 5.39486C10.2074 5.36469 10.1293 5.34917 10.0505 5.34917C9.97166 5.34917 9.8936 5.36469 9.82076 5.39486C9.74793 5.42503 9.68175 5.46925 9.626 5.525L8 7.151Z"
                                    fill={
                                      file.size / 1024 < 5120
                                        ? "#195097"
                                        : "#e00b0b"
                                    }
                                  />
                                </svg>
                              </div>
                            </div>
                          ))}
                          <Button className="chat-main-wrapper-footer-submit-btn"
                            disabled={loading}
                            onClick={handleSubmit}
                          >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            fill="#fff"
                            height="20"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M.172 3.708A2.745 2.745 0 01.917.713 2.745 2.745 0 013.965.282l18.09 8.444A3.584 3.584 0 0124 10.999H4.048L.229 3.835a1.446 1.446 0 01-.057-.127zM4.062 13L.309 20.175a.944.944 0 00-.054.122 2.744 2.744 0 00.749 2.993 2.756 2.756 0 001.841.708c.409 0 .819-.092 1.201-.279l18.011-8.438a3.582 3.582 0 001.945-2.28H4.062z"></path>
                          </svg>
                          {loading ? "Uploading..." : "Upload"}
                        </Button>
                        </>
                      ) : (
                        <Image
                          className="file-upload-option-img"
                          src={
                            window.location.origin +
                            "/img/upload/upload-right-img.png"
                          }
                          type="image/png"
                        />
                      )}
                    </div> */}
                    <div className="welcome-right-sec">
                      <div className="welcome-instruction-content">
                        <div className="welcome-instruction-icon-sec">
                          <Image
                            className="welcome-upload-icon-sec"
                            src={
                              window.location.origin +
                              "/img/welcome/upload-file-img.png"
                            }
                          />
                        </div>
                        <h3>
                          Seed the bot knowledge base by uploading training
                          resources.
                        </h3>
                        <ul className="list-unstyled welcome-instruction-list">
                          <li>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="22.76"
                                height="22.3251"
                                rx="11.1625"
                                fill="#195097"
                              ></rect>
                              <path
                                d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                                fill="white"
                                stroke="white"
                              ></path>
                              <rect
                                x="0.5"
                                y="0.5"
                                width="22.76"
                                height="22.3251"
                                rx="11.1625"
                                stroke="#195097"
                              ></rect>
                            </svg>
                            <span>
                              See what it's like when all the tools and data an
                              agent needs are unified on a single pane of glass,
                              providing the entire context of a customer's
                              issue.
                            </span>
                          </li>
                          <li>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="22.76"
                                height="22.3251"
                                rx="11.1625"
                                fill="#195097"
                              ></rect>
                              <path
                                d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                                fill="white"
                                stroke="white"
                              ></path>
                              <rect
                                x="0.5"
                                y="0.5"
                                width="22.76"
                                height="22.3251"
                                rx="11.1625"
                                stroke="#195097"
                              ></rect>
                            </svg>
                            <span>
                              Watch how customer interactions can be converted
                              seamlessly between channels, keeping all data and
                              context preserved.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default UploadIndex;
