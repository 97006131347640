import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  serviceStatusStart,
  startLLMServiceStart,
  stopLLMServiceStart,
} from "../../store/slices/CommonSlice";

const EmPowerModal = (props) => {
  const dispatch = useDispatch();
  const serviceStatus = useSelector((state) => state.common.serviceStatus);
  const [skipRender, setSkipRender] = useState(true);
  const startLLMService = useSelector((state) => state.common.startLLMService);
  const stopLLMService = useSelector((state) => state.common.stopLLMService);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    props.emPower &&
      dispatch(serviceStatusStart())
  }, [props.emPower]);

  useEffect(() => {
    if (!skipRender && !serviceStatus.loading && Object.keys(serviceStatus.data).length > 0) {
      setStatus(serviceStatus.data.status == "API is working" ? 1 : 0);
    }
    setSkipRender(false);
  }, [serviceStatus]);

  useEffect(() => {
    if (!skipRender && !startLLMService.loading && Object.keys(startLLMService.data).length > 0) {
      setStatus(1);
      props.closeEmPowerModal();
    }
    setSkipRender(false);
  }, [startLLMService]);

  useEffect(() => {
    if (!skipRender && !stopLLMService.loading && Object.keys(stopLLMService.data).length > 0) {
      setStatus(0)
      props.closeEmPowerModal();
    }
    setSkipRender(false);
  }
  , [stopLLMService]);

  return (
    <>
      <Modal
        className="modal-dialog-center rename-thread-modal"
        size="md"
        centered
        show={props.emPower}
        onHide={props.closeEmPowerModal}
      >
        <Modal.Body>
          <Button
            className="modal-close"
            onClick={() => props.closeEmPowerModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 11 11"
            >
              <path
                fill="#979BA2"
                d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
              ></path>
            </svg>
          </Button>
          <div className="empower-card">
            <div className="empower-icon-sec">
              <Image
                className="empower-icon"
                src={window.location.origin + "/img/empower-icon.svg"}
              />
            </div>
            <div className="empower-info">
              <h3>Empower</h3>
              <p>
                Enjoy ultimate control over HaiVe experience with the
                flexibility to start and stop service as your workflow demands.
              </p>
              <div className="empower-service-status">
                {serviceStatus.buttonDisable
                  ? "Checking Service Status"
                  : "The LLM Service is currently"}{" "}
                <span className="running-service">
                  {status ? "Running..." : "Stopped..."}
                </span>
              </div>
              <div className="empower-btn-sec">
                {status ? (
                  <Button
                    disabled={serviceStatus.buttonDisable || stopLLMService.buttonDisable}
                    onClick={() => dispatch(stopLLMServiceStart())}
                    className="default-btn stop-btn"
                  >
                    Stop Service
                  </Button>
                ) : (
                  <Button
                    disabled={serviceStatus.buttonDisable || startLLMService.buttonDisable}
                    onClick={() => dispatch(startLLMServiceStart())}
                    className="default-btn start-btn"
                  >
                    Start Service
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmPowerModal;
