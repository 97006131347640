import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LandingPageIndex = (props) => {

  const navigate = useNavigate();
  useEffect(() => {
    localStorage.getItem("dashboard_tour") != 1 ? navigate("/welcome") : navigate("/upload");
  }, []);

  return (
    <>
    </>
  );
};

export default LandingPageIndex;
