import React, { useEffect, useState } from "react";
import { Navbar, Container, Image, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../../store/slices/CommonSlice";
import EmPowerModal from "../../DashBoard/EmPowerModal";

const CollapseSidebar = (props) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const [emPower, setEmPower] = useState(false);
  const [status, setStatus] = useState(0);
  const [skipRender, setSkipRender] = useState(true);
  const serviceStatus = useSelector((state) => state.common.serviceStatus);

  const closeEmPowerModal = () => {
    setEmPower(false);
  };

  useEffect(() => {
    if (!serviceStatus.buttonDisable && Object.keys(serviceStatus.data).length > 0) {
      setStatus(serviceStatus.data.status == "API is working" ? 1 : 0);
    }
    setSkipRender(false);
  }, [serviceStatus]);

  return (
    <>
      <div className={`admin-ui-sidebar admin-ui-collapse-sidebar`}>
        <div className="admin-ui-sidebar-collapse">
          <div className="admin-ui-logo-sec">
            <Link className="" to="/">
              <Image
                className="admin-ui-logo mobile-logo"
                src={window.location.origin + "/img/file-refiner-logo.png"}
              />
            </Link>
            <Button
              className="offcanvas-close"
              onClick={() => dispatch(toggleSidebar())}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="#fff"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M23.341 9.48l-3.501-6A5.016 5.016 0 0015.522 1H8.451a5.02 5.02 0 00-4.319 2.48l-3.501 6a5.01 5.01 0 000 5.039l3.501 6a5.016 5.016 0 004.318 2.48h7.071a5.02 5.02 0 004.319-2.48l3.5-6a5.01 5.01 0 000-5.039zm-1.729 4.031l-3.499 6a3.011 3.011 0 01-2.592 1.488H8.45a3.01 3.01 0 01-2.591-1.488l-3.5-6a3.004 3.004 0 010-3.023l3.499-6A3.011 3.011 0 018.45 3h7.071a3.01 3.01 0 012.591 1.488l3.5 6a3.004 3.004 0 010 3.023zm-5.905-3.805l-2.293 2.293 2.293 2.293a.999.999 0 11-1.414 1.414L12 13.413l-2.293 2.293a.997.997 0 01-1.414 0 .999.999 0 010-1.414l2.293-2.293-2.293-2.293a.999.999 0 111.414-1.414L12 10.585l2.293-2.293a.999.999 0 111.414 1.414z"></path>
              </svg>
            </Button>
          </div>
          <div className="admin-ui-collapse-body">
            <ul className="admin-ui-sidebar-list list-unstyled" onClick={()=> dispatch(toggleSidebar())}>
              <li>
                <Link
                  to="/upload"
                  className={`${location.pathname == "/upload" && "active"}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 15v4c0 2.757-2.243 5-5 5H5c-2.757 0-5-2.243-5-5v-4c0-1.654 1.346-3 3-3h3c1.103 0 2 .897 2 2s.897 2 2 2h4c1.103 0 2-.897 2-2s.897-2 2-2h3c1.654 0 3 1.346 3 3zM8.707 5.293L11 3v7a1 1 0 102 0V3l2.293 2.293a.997.997 0 001.414 0 .999.999 0 000-1.414L13.414.586a1.99 1.99 0 00-1.4-.583L12 0l-.014.003a1.989 1.989 0 00-1.4.583L7.293 3.879a.999.999 0 101.414 1.414z"></path>
                  </svg>
                  <span>Upload</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/file-uploading"
                  className={`${
                    location.pathname == "/file-uploading" && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19.5 24a1 1 0 01-.929-.628l-.844-2.113-2.116-.891a1.007 1.007 0 01.035-1.857l2.088-.791.837-2.092a1.008 1.008 0 011.858 0l.841 2.1 2.1.841a1.007 1.007 0 010 1.858l-2.1.841-.841 2.1A1 1 0 0119.5 24zM10 21a2 2 0 01-1.936-1.413L6.45 14.54l-5.063-1.694a2.032 2.032 0 01.052-3.871l5.023-1.534 1.692-5.054A1.956 1.956 0 0110.108 1a2 2 0 011.917 1.439l1.532 5.015 5.03 1.61a2.042 2.042 0 010 3.872l-5.039 1.612-1.612 5.039A2 2 0 0110 21zM20.5 7a1 1 0 01-.97-.757l-.357-1.43-1.433-.385a1 1 0 01.034-1.94l1.4-.325.356-1.406a1 1 0 011.94 0l.354 1.418 1.418.355a1 1 0 010 1.94l-1.418.355-.354 1.418A1 1 0 0120.5 7z"></path>
                  </svg>
                  <span>Prompt</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/chat"
                  className={`${location.pathname == "/chat" && "active"}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M14 7V.46a6.977 6.977 0 012.465 1.59l3.484 3.486A6.954 6.954 0 0121.54 8H15a1 1 0 01-1-1zm8 3.485V19a5.006 5.006 0 01-5 5H7a5.006 5.006 0 01-5-5V5a5.006 5.006 0 015-5h4.515c.163 0 .324.013.485.024V7a3 3 0 003 3h6.976c.011.161.024.322.024.485zM14 19a1 1 0 00-1-1H8a1 1 0 000 2h5a1 1 0 001-1zm3-4a1 1 0 00-1-1H8a1 1 0 000 2h8a1 1 0 001-1z"></path>
                  </svg>
                  <span>File</span>
                </Link>
              </li>
            </ul>
            <ul className={`admin-ui-sidebar-list empower-icon list-unstyled ${status ? "status-active" : ""}`}>
              <li>
                <Link onClick={() => setEmPower(true)} type="button" className={`${status ? "status-active" : ""}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 512.292 512.292"
                    version="1.1"
                    viewBox="0 0 512.292 512.292"
                    xmlSpace="preserve"
                    fill="#195097"
                  >
                    <path d="M256.061 0c17.673 0 32 14.327 32 32v106.667c0 17.673-14.327 32-32 32-17.673 0-32-14.327-32-32V32c0-17.673 14.326-32 32-32z"></path>
                    <path d="M330.727 105.387a30.998 30.998 0 0014.315 26.581c80.432 49.143 105.796 154.185 56.652 234.616S247.51 472.38 167.078 423.237 61.282 269.052 110.426 188.62a170.676 170.676 0 0156.269-56.418 31.384 31.384 0 0014.699-26.795c.049-17.673-14.238-32.039-31.911-32.088a31.994 31.994 0 00-17.155 4.931C22.233 146.634-11.58 291.318 56.803 401.412s213.067 143.907 323.161 75.524 143.907-213.067 75.524-323.161a234.675 234.675 0 00-75.524-75.524c-14.997-9.461-34.824-4.973-44.285 10.024a32.106 32.106 0 00-4.952 17.112z"></path>
                  </svg>
                  <span className={`${status ? "status-active" : ""}`}>Empower</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <EmPowerModal
        emPower={emPower}
        closeEmPowerModal={closeEmPowerModal}
        setEmPower={setEmPower}
      />
    </>
  );
};

export default CollapseSidebar;
