import React, { useState, useEffect } from "react";
import { Row, Col, Button, Tab, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { contextListStart, contextListSuccess, deleteFileStart, setChats, setSelectedContext, setSelectedFile, trainedFileInfoStart } from "../../store/slices/CommonSlice";
import FileUploadingProcess from "./FileUploadingProcess";
import FileUploadingInitisate from "./FileUploadingInitiate";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import { useNavigate } from "react-router-dom";

const FileUploadIndex = (props) => {

  const dispatch = useDispatch();
  const trainedFileInfo = useSelector((state) => state.common.trainedFileInfo);
  const contextList = useSelector((state) => state.common.contextList);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const userId = useSelector((state) => state.common.userId);

  const handleNewThread = (file) => {
    if(!contextList.buttonDisable && Object.keys(contextList.data).length > 0) {
      dispatch(setSelectedFile(file));
      navigate("/chat")
    }
  }

  useEffect(() => {
    setStep(1);
    dispatch(contextListStart({
      bot_id: userId,
      user_id: userId,
    }));
    dispatch(trainedFileInfoStart({
        user_id: userId,
        bot_id: userId,
      })
    );
  }, []);

  return (
    <>
      <div className="admin-ui-body-sec">
        <div className="file-upload-sec-wrapped">
          <Row>
            <Col md={5}>
              <div className="file-uploading-processing-wrapped">
                {step == 1 && <FileUploadingInitisate setStep={setStep} />}
                {step == 2 && <FileUploadingProcess setStep={setStep} />}
                {/* {step == 3 && <GeneratedVoiceAudio setStep={setStep} />} */}
                {/* <FileUploadingProcess /> */}
                {/* <FileUploadingInitisate /> */}
              </div>
            </Col>
            <Col md={7}>
              <div className="file-upload-file-card-wrapped">
                <div className="file-upload-title">
                  <div className="file-upload-title-info">
                    <h4>Training Files</h4>
                  </div>
                  <div className="file-upload-title-btn">
                    <Button className="btn-no-border-bg">Remove All</Button>
                  </div>
                </div>
                <div className="file-tabs-card-wrapped">
                  <Tab.Container
                    id="file-uploading-tabs"
                    defaultActiveKey="all"
                  >
                    <Nav variant="pills" className="flex-row">
                      <Nav.Item>
                        <Nav.Link eventKey="all">
                          All Uploads<span>{trainedFileInfo.buttonDisable ? "-" : Object.keys(trainedFileInfo.data).length > 0 ? trainedFileInfo.data.data.trained_file_names.length + trainedFileInfo.data.data.non_trained_file_names.length : "0"}</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="complete">
                          Completed <span>{trainedFileInfo.buttonDisable ? "-" : Object.keys(trainedFileInfo.data).length > 0 ? trainedFileInfo.data.data.trained_file_names.length : "0"}</span>{" "}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="faile">
                          Incomplete <span>{trainedFileInfo.buttonDisable ? "-" : Object.keys(trainedFileInfo.data).length > 0 ? trainedFileInfo.data.data.non_trained_file_names.length : "0"}</span>{" "}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="all">
                        <div className="file-uploading-wrapped">
                          {trainedFileInfo.buttonDisable ? <Skeleton className="mb-2" containerClassName="skeleton" count={5} height={60} /> : (Object.keys(trainedFileInfo.data).length > 0 && (trainedFileInfo.data.data.trained_file_names.length > 0 || trainedFileInfo.data.data.non_trained_file_names.length > 0)) ? 
                            <React.Fragment>
                              {trainedFileInfo.data.data.trained_file_names.length > 0 ? trainedFileInfo.data.data.trained_file_names.map((item, key) => (
                                <div className="file-upload-full-card" key={key}>
                                  <div className="file-upload-full-card-info">
                                    <div className="file-upload-card-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        x="0"
                                        y="0"
                                        enableBackground="new 0 0 507.506 507.506"
                                        version="1.1"
                                        viewBox="0 0 507.506 507.506"
                                        xmlSpace="preserve"
                                        fill="#195097"
                                      >
                                        <path d="M163.865 436.934a54.228 54.228 0 01-38.4-15.915L9.369 304.966c-12.492-12.496-12.492-32.752 0-45.248 12.496-12.492 32.752-12.492 45.248 0l109.248 109.248L452.889 79.942c12.496-12.492 32.752-12.492 45.248 0 12.492 12.496 12.492 32.752 0 45.248L202.265 421.019a54.228 54.228 0 01-38.4 15.915z"></path>
                                      </svg>
                                    </div>
                                    <div className="file-upload-card-info">
                                      <h4>{item}</h4>
                                      {/* <p>
                                        Uploaded to <span> Folder Name </span>
                                      </p> */}
                                    </div>
                                    <div className="file-upload-card-size">
                                      <p className="file-upload-type">{item.substring(item.lastIndexOf('.')+1).toUpperCase()}</p>
                                      {/* <p className="file-size">2.5Mb</p> */}
                                    </div>
                                  </div>
                                  <div className="file-upload-full-card-btn" style={{display:"flex", gap:'1em'}}>
                                    <OverlayTrigger
                                      placement="top"
                                      trigger={["hover"]}
                                      overlay={
                                        <Tooltip id="tooltip">
                                          <strong>New Thread</strong>
                                        </Tooltip>
                                      }>
                                      <Button className="new-threads-btn" onClick={() => handleNewThread(item)}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          fill="none"
                                          viewBox="0 0 20 18"
                                        >
                                          <g clipPath="url(#clip0_117_1767)">
                                            <path
                                              fill="#195097"
                                              fillRule="evenodd"
                                              d="M10.8 1.338H4.333c-1.149 0-2.251.47-3.064 1.306A4.526 4.526 0 000 5.797V16.5a.913.913 0 00.254.63.854.854 0 00.613.261H13c1.15 0 2.251-.47 3.064-1.306a4.526 4.526 0 001.27-3.153V9.365c-3.682 0-6.667-2.995-6.667-6.69 0-.457.046-.905.133-1.337zM5.49 7.806a.854.854 0 01.576-.225c.23 0 .45.094.614.261a.907.907 0 01.254.63v1.784l-.007.105a.899.899 0 01-.304.578.85.85 0 01-1.187-.071.907.907 0 01-.236-.612V8.473l.006-.105a.899.899 0 01.285-.562zm5.164.036a.845.845 0 011.226 0 .904.904 0 01.254.63v1.784a.904.904 0 01-.254.631.851.851 0 01-1.226 0 .907.907 0 01-.254-.63V8.472c0-.237.091-.464.254-.63z"
                                              clipRule="evenodd"
                                            ></path>
                                            <path
                                              stroke="#195097"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M13.913 3.545h2.667m0 0h2.666m-2.666 0V.87m0 2.675v2.676"
                                            ></path>
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_117_1767">
                                              <path fill="#fff" d="M0 0H20V17.391H0z"></path>
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="top"
                                      trigger={["hover"]}
                                      overlay={
                                        <Tooltip id="tooltip">
                                          <strong>Delete File</strong>
                                        </Tooltip>
                                      }>
                                      <Button
                                        className="btn-no-border-bg"
                                        onClick={() => dispatch(deleteFileStart({
                                          user_id: userId,
                                          bot_id: userId,
                                          file_name: item,
                                        }))}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          enableBackground="new 0 0 512 512"
                                          viewBox="0 0 24 24"
                                          fill="#195097"
                                        >
                                          <path
                                            fill="#"
                                            d="M14.121 12L18 8.117A1.5 1.5 0 0015.883 6L12 9.879 8.11 5.988A1.5 1.5 0 105.988 8.11L9.879 12 6 15.882A1.5 1.5 0 108.118 18L12 14.121 15.878 18A1.5 1.5 0 0018 15.878z"
                                            data-original="#000000"
                                          ></path>
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              )): null}
                              {trainedFileInfo.data.data.non_trained_file_names.length > 0 ? trainedFileInfo.data.data.non_trained_file_names.map((item, key) => (
                                <div className="file-upload-full-card" key={key}>
                                  <div className="file-upload-full-card-info">
                                    <div className="file-upload-card-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="#FF6060"
                                      >
                                        <path d="M14.121 12L18 8.117A1.5 1.5 0 0015.883 6L12 9.879 8.11 5.988A1.5 1.5 0 105.988 8.11L9.879 12 6 15.882A1.5 1.5 0 108.118 18L12 14.121 15.878 18A1.5 1.5 0 0018 15.878z"></path>
                                      </svg>
                                    </div>
                                    <div className="file-upload-card-info">
                                      <h4>{item}</h4>
                                      {/* <p>
                                        Uploaded to <span> Folder Name </span>
                                      </p> */}
                                    </div>
                                    <div className="file-upload-card-size">
                                      <p className="file-upload-type">{item.substring(item.lastIndexOf('.')+1).toUpperCase()}</p>
                                      {/* <p className="file-size">2.5Mb</p> */}
                                    </div>
                                  </div>
                                  <div className="file-upload-full-card-btn">
                                    <OverlayTrigger
                                      placement="top"
                                      trigger={["hover"]}
                                      overlay={
                                        <Tooltip id="tooltip">
                                          <strong>Delete File</strong>
                                        </Tooltip>
                                      }>
                                      <Button
                                        className="btn-no-border-bg"
                                        onClick={() => dispatch(deleteFileStart({
                                          user_id: userId,
                                          bot_id: userId,
                                          file_name: item,
                                        }))}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          enableBackground="new 0 0 512 512"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="#d5d5d5"
                                            d="M14.121 12L18 8.117A1.5 1.5 0 0015.883 6L12 9.879 8.11 5.988A1.5 1.5 0 105.988 8.11L9.879 12 6 15.882A1.5 1.5 0 108.118 18L12 14.121 15.878 18A1.5 1.5 0 0018 15.878z"
                                            data-original="#000000"
                                          ></path>
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              )): null} 
                            </React.Fragment> : 
                          <NoDataFound/>} 
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="complete">
                        <div className="file-uploading-wrapped">
                          {trainedFileInfo.loading ? <Skeleton className="mb-2" containerClassName="skeleton" count={5} height={60} /> : (Object.keys(trainedFileInfo.data).length > 0 && trainedFileInfo.data.data.trained_file_names.length > 0) ? 
                            <React.Fragment>
                              {trainedFileInfo.data.data.trained_file_names.map((item, key) => (
                                <div className="file-upload-full-card" key={key}>
                                  <div className="file-upload-full-card-info">
                                    <div className="file-upload-card-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        x="0"
                                        y="0"
                                        enableBackground="new 0 0 507.506 507.506"
                                        version="1.1"
                                        viewBox="0 0 507.506 507.506"
                                        xmlSpace="preserve"
                                        fill="#195097"
                                      >
                                        <path d="M163.865 436.934a54.228 54.228 0 01-38.4-15.915L9.369 304.966c-12.492-12.496-12.492-32.752 0-45.248 12.496-12.492 32.752-12.492 45.248 0l109.248 109.248L452.889 79.942c12.496-12.492 32.752-12.492 45.248 0 12.492 12.496 12.492 32.752 0 45.248L202.265 421.019a54.228 54.228 0 01-38.4 15.915z"></path>
                                      </svg>
                                    </div>
                                    <div className="file-upload-card-info">
                                      <h4>{item}</h4>
                                      {/* <p>
                                        Uploaded to <span> Folder Name </span>
                                      </p> */}
                                    </div>
                                    <div className="file-upload-card-size">
                                      <p className="file-upload-type">{item.substring(item.lastIndexOf('.')+1).toUpperCase()}</p>
                                      {/* <p className="file-size">2.5Mb</p> */}
                                    </div>
                                  </div>
                                  <div className="file-upload-full-card-btn">
                                    <OverlayTrigger
                                      placement="top"
                                      trigger={["hover"]}
                                      overlay={
                                        <Tooltip id="tooltip">
                                          <strong>Delete File</strong>
                                        </Tooltip>
                                      }>
                                      <Button
                                        className="btn-no-border-bg"
                                        onClick={() => dispatch(deleteFileStart({
                                          user_id: userId,
                                          bot_id: userId,
                                          file_name: item,
                                        }))}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          enableBackground="new 0 0 512 512"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="#d5d5d5"
                                            d="M14.121 12L18 8.117A1.5 1.5 0 0015.883 6L12 9.879 8.11 5.988A1.5 1.5 0 105.988 8.11L9.879 12 6 15.882A1.5 1.5 0 108.118 18L12 14.121 15.878 18A1.5 1.5 0 0018 15.878z"
                                            data-original="#000000"
                                          ></path>
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              ))}
                            </React.Fragment> : 
                          <NoDataFound/>} 
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="faile">
                        <div className="file-uploading-wrapped">
                          {trainedFileInfo.loading ? <Skeleton className="mb-2" containerClassName="skeleton" count={5} height={60} /> : (Object.keys(trainedFileInfo.data).length > 0 && trainedFileInfo.data.data.non_trained_file_names.length > 0) ? 
                            <React.Fragment>
                              {trainedFileInfo.data.data.non_trained_file_names.map((item, key) => (
                                <div className="file-upload-full-card" key={key}>
                                  <div className="file-upload-full-card-info">
                                    <div className="file-upload-card-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="#FF6060"
                                      >
                                        <path d="M14.121 12L18 8.117A1.5 1.5 0 0015.883 6L12 9.879 8.11 5.988A1.5 1.5 0 105.988 8.11L9.879 12 6 15.882A1.5 1.5 0 108.118 18L12 14.121 15.878 18A1.5 1.5 0 0018 15.878z"></path>
                                      </svg>
                                    </div>
                                    <div className="file-upload-card-info">
                                      <h4>{item}</h4>
                                      {/* <p>
                                        Uploaded to <span> Folder Name </span>
                                      </p> */}
                                    </div>
                                    <div className="file-upload-card-size">
                                      <p className="file-upload-type">{item.substring(item.lastIndexOf('.')+1).toUpperCase()}</p>
                                      {/* <p className="file-size">2.5Mb</p> */}
                                    </div>
                                  </div>
                                  <div className="file-upload-full-card-btn">
                                    <OverlayTrigger
                                      placement="top"
                                      trigger={["hover"]}
                                      overlay={
                                        <Tooltip id="tooltip">
                                          <strong>Delete File</strong>
                                        </Tooltip>
                                      }>
                                      <Button
                                        className="btn-no-border-bg"
                                        onClick={() => dispatch(deleteFileStart({
                                          user_id: userId,
                                          bot_id: userId,
                                          file_name: item,
                                        }))}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          enableBackground="new 0 0 512 512"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="#d5d5d5"
                                            d="M14.121 12L18 8.117A1.5 1.5 0 0015.883 6L12 9.879 8.11 5.988A1.5 1.5 0 105.988 8.11L9.879 12 6 15.882A1.5 1.5 0 108.118 18L12 14.121 15.878 18A1.5 1.5 0 0018 15.878z"
                                            data-original="#000000"
                                          ></path>
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              ))}
                            </React.Fragment> : 
                          <NoDataFound/>} 
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default FileUploadIndex;
