import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Dropdown, Offcanvas } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../store/slices/CommonSlice";
import SidebarIndex from "../layouts/Header/SidebarIndex";



const DashBoardIndex = (props) => {
  const dispatch = useDispatch();
  const sidebar = useSelector(state => state.common.sidebar);
  return (
    <>
      <div className="admin-ui-sec">
        <SidebarIndex />
        <div className={`admin-ui-main-wrapper ${sidebar?"admin-collapse-main-wrapper":""}`}>
          <div className="admin-ui-header-sec">
            <Navbar expand="lg">
              <Container fluid>
                <div className="admin-ui-title-sec">
                  <div className="admin-ui-collapse-btn"
                  onClick={()=>dispatch(toggleSidebar())}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#195097"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 3.5c0 .83-.67 1.5-1.5 1.5h-21C.67 5 0 4.33 0 3.5S.67 2 1.5 2h21c.83 0 1.5.67 1.5 1.5zM6.5 20h-5c-.83 0-1.5.67-1.5 1.5S.67 23 1.5 23h5c.83 0 1.5-.67 1.5-1.5S7.33 20 6.5 20zm8-9h-13c-.83 0-1.5.67-1.5 1.5S.67 14 1.5 14h13c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z"></path>
                    </svg>
                  </div>
                  <h2>Dashboard</h2>
                </div>
                {/* <Navbar.Toggle aria-controls="basic-navbar-nav">
                  <Image
                    className="hamburger-menu"
                    src={
                      window.location.origin + "/images/hamburger-menu.png"
                    }
                    type="image/png"
                  />
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ms-auto">
                    <div className="user-dropdown-sec">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <Image
                            className="user-dropdown-img"
                            src={
                              window.location.origin + "/images/user-img.png"
                            }
                            type="image/png"
                          />
                          <span>Ben Dover</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 8a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"></path>
                              <path d="M21.294 13.9l-.444-.256a9.1 9.1 0 000-3.29l.444-.256a3 3 0 10-3-5.2l-.445.257A8.977 8.977 0 0015 3.513V3a3 3 0 00-6 0v.513a8.977 8.977 0 00-2.848 1.646L5.705 4.9a3 3 0 00-3 5.2l.444.256a9.1 9.1 0 000 3.29l-.444.256a3 3 0 103 5.2l.445-.257A8.977 8.977 0 009 20.487V21a3 3 0 006 0v-.513a8.977 8.977 0 002.848-1.646l.447.258a3 3 0 003-5.2zm-2.548-3.776a7.048 7.048 0 010 3.75 1 1 0 00.464 1.133l1.084.626a1 1 0 01-1 1.733l-1.086-.628a1 1 0 00-1.215.165 6.984 6.984 0 01-3.243 1.875 1 1 0 00-.751.969V21a1 1 0 01-2 0v-1.252a1 1 0 00-.751-.969A6.984 6.984 0 017.006 16.9a1 1 0 00-1.215-.165l-1.084.627a1 1 0 11-1-1.732l1.084-.626a1 1 0 00.464-1.133 7.048 7.048 0 010-3.75 1 1 0 00-.465-1.129l-1.084-.626a1 1 0 011-1.733l1.086.628A1 1 0 007.006 7.1a6.984 6.984 0 013.243-1.875A1 1 0 0011 4.252V3a1 1 0 012 0v1.252a1 1 0 00.751.969A6.984 6.984 0 0116.994 7.1a1 1 0 001.215.165l1.084-.627a1 1 0 111 1.732l-1.084.626a1 1 0 00-.463 1.129z"></path>
                            </svg>
                            <span>Settings</span>
                          </Dropdown.Item>
                          <Dropdown.Item href="#">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zM12 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                            </svg>
                            <span>My Account</span>
                          </Dropdown.Item>
                          <Dropdown.Item href="#">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                            >
                              <path d="M11.476 15a1 1 0 00-1 1v3a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h2.476a3 3 0 013 3v3a1 1 0 002 0V5a5.006 5.006 0 00-5-5H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h2.476a5.006 5.006 0 005-5v-3a1 1 0 00-1-1z"></path>
                              <path d="M22.867 9.879l-4.586-4.586a1 1 0 10-1.414 1.414l4.263 4.263L6 11a1 1 0 000 2l15.188-.03-4.323 4.323a1 1 0 101.414 1.414l4.586-4.586a3 3 0 00.002-4.242z"></path>
                            </svg>
                            <span>Logout</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="header-search">
                        
                    </div>
                  </Nav>
                </Navbar.Collapse> */}
              </Container>
            </Navbar>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoardIndex;
