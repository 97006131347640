import React, { useState } from "react";
import {
 Form,
 InputGroup,
  Button,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const ChatMobileMainWrapper = (props) => {

  return (
    <>

    <div className="chat-main-wrapper-sec chat-mobile-wrapper-sec">
        <div className="chat-main-wrapper-header-sec">
        <Form className="chat-search-form-sec">
            <div className="chat-search-header-card">
            <InputGroup className="mb-0">
                <Form.Control
                placeholder="Type a command or search..."
                />
                {/* <InputGroup.Text id="basic-addon2">
                <Button type="button" className="search-btn">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="none"
                    viewBox="0 0 24 24"
                    >
                    <g>
                        <path
                        stroke="#03363D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M16 16l-4-4m0 0L8 8m4 4l4-4m-4 4l-4 4"
                        ></path>
                    </g>
                    </svg>
                </Button>
                </InputGroup.Text> */}
                <InputGroup.Text id="basic-addon2">
                <Button type="button" className="search-btn">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 18 18"
                    >
                    <path
                        fill="#03363D"
                        stroke="#03363D"
                        d="M16.806 15.873l-4.208-4.207A6.532 6.532 0 007.374 1.002a6.537 6.537 0 00-6.372 6.362 6.532 6.532 0 006.033 6.684 6.537 6.537 0 004.628-1.448l4.208 4.206a.662.662 0 00.935-.935v.002zM2.353 7.549A5.19 5.19 0 018.56 2.46a5.193 5.193 0 013.784 7.078 5.191 5.191 0 01-8.469 1.682 5.195 5.195 0 01-1.522-3.67z"
                    ></path>
                    </svg>
                </Button>
                </InputGroup.Text>
            </InputGroup>
            <Button className="chat-clear-btn">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                x="0"
                y="0"
                enableBackground="new 0 0 500.696 500.696"
                version="1.1"
                viewBox="0 0 500.696 500.696"
                xmlSpace="preserve"
                >
                <path d="M121.232 158.288l-44.245 12.971a107.438 107.438 0 00-66.411 56.213 106.095 106.095 0 00-2.923 85.739c.149.384.341.747.491 1.131l36.693-5.995a204.793 204.793 0 0091.733-51.925c8.424-8.247 21.939-8.104 30.187.32 8.247 8.424 8.104 21.939-.32 30.187a248.514 248.514 0 01-112.96 63.168l-25.003 4.139a272.556 272.556 0 0052.907 62.507l43.797-16.256 29.867-14.933a203.613 203.613 0 0076.608-66.944l11.072-16.235c6.366-9.914 19.563-12.791 29.478-6.426 9.914 6.366 12.791 19.563 6.426 29.478-.211.329-.432.652-.661.969l-11.051 16.235a246.085 246.085 0 01-92.736 81.067l-33.067 16.491-17.984 6.656a282.152 282.152 0 00142.805 38.4h19.712a21.566 21.566 0 0012.331-3.883 352.134 352.134 0 00109.291-119.168L174.906 134.053a270.07 270.07 0 01-53.674 24.235zM494.373 21.755c-8.433-8.403-22.074-8.403-30.507 0L362.234 123.387l-6.827-6.677c-37.156-36.291-95.028-40.603-137.152-10.219-1.963 1.408-3.904 2.581-5.888 3.947l213.909 210.005a303.48 303.48 0 0014.933-56.875 107.286 107.286 0 00-30.997-93.312l-17.067-16.725 101.271-101.27c8.391-8.444 8.372-22.085-.043-30.506z"></path>
                </svg>
            </Button>
            </div>
        </Form>
        </div>
        <div className="chat-main-wrapper-body-sec">
            <div className="chat-main-sender-sec">
                <div className="chat-main-sender-icon-sec">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#195097"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                >
                    <circle cx="256" cy="128" r="128"></circle>
                    <path d="M256 298.667c-105.99.118-191.882 86.01-192 192C64 502.449 73.551 512 85.333 512h341.333c11.782 0 21.333-9.551 21.333-21.333-.117-105.99-86.009-191.883-191.999-192z"></path>
                </svg>
                </div>
                <div className="chat-main-sender-message-sec">
                <div className="chat-main-sender-message">
                    The advantages of Artificial Intelligence
                </div>
                <Button className="chat-main-sender-edit-btn">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                        fill="#9DA3AE"
                        fillRule="evenodd"
                        d="M3.25 22a.75.75 0 01.75-.75h16a.75.75 0 010 1.5H4a.75.75 0 01-.75-.75z"
                        clipRule="evenodd"
                        ></path>
                        <path
                        fill="#9DA3AE"
                        d="M11.52 14.929l5.917-5.917a8.232 8.232 0 01-2.661-1.787 8.232 8.232 0 01-1.788-2.662L7.07 10.48c-.462.462-.693.692-.891.947a5.24 5.24 0 00-.599.969c-.139.291-.242.601-.449 1.22l-1.088 3.267a.848.848 0 001.073 1.073l3.266-1.088c.62-.207.93-.31 1.221-.45.344-.163.669-.364.969-.598.255-.199.485-.43.947-.891zM19.079 7.37a3.146 3.146 0 00-4.45-4.449l-.71.71.031.09c.26.749.751 1.732 1.674 2.655A7.003 7.003 0 0018.37 8.08l.71-.71z"
                        ></path>
                    </svg>
                </Button>
                </div>
            </div>
            <div className="chat-main-receiver-sec">
            <div className="chat-main-receiver-icon-sec">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="20" height="20" data-name="Layer 1" viewBox="0 0 24 24"><path d="M19.5 24a1 1 0 01-.929-.628l-.844-2.113-2.116-.891a1.007 1.007 0 01.035-1.857l2.088-.791.837-2.092a1.008 1.008 0 011.858 0l.841 2.1 2.1.841a1.007 1.007 0 010 1.858l-2.1.841-.841 2.1A1 1 0 0119.5 24zM10 21a2 2 0 01-1.936-1.413L6.45 14.54l-5.063-1.694a2.032 2.032 0 01.052-3.871l5.023-1.534 1.692-5.054A1.956 1.956 0 0110.108 1a2 2 0 011.917 1.439l1.532 5.015 5.03 1.61a2.042 2.042 0 010 3.872l-5.039 1.612-1.612 5.039A2 2 0 0110 21zM20.5 7a1 1 0 01-.97-.757l-.357-1.43-1.433-.385a1 1 0 01.034-1.94l1.4-.325.356-1.406a1 1 0 011.94 0l.354 1.418 1.418.355a1 1 0 010 1.94l-1.418.355-.354 1.418A1 1 0 0120.5 7z"></path></svg>
            </div>
            <div className="chat-main-receiver-message-sec">
                <div className="chat-main-receiver-message">
                    Unfortunately, the question "What's the average speed of a unicorn?" has no definitive answer as unicorns are mythical creatures. While they are often depicted as swift and graceful, there is no scientific data to confirm their actual speed.
                    However, we can explore some possible interpretations of the question:
                    1. Unicorns in Myth and Legend:
                    In mythology and folklore, unicorns are often described as being very fast. They are frequently depicted galloping across meadows or leaping over obstacles. However, there are no specific references to their exact speed.
                    Some literary works, like the Chronicles of Narnia, have attempted to quantify their speed. For example, in The Horse and His Boy, a unicorn is described as being able to outpace a group of horses.
                    Based on these descriptions, we can make an educated guess that unicorns are likely much faster than average horses, which can reach speeds of up to 55 mph.
                    2. Unicorns as Startup Companies:
                    In modern business terminology, "unicorn" refers to a startup company with a valuation of over $1 billion.
                    The "speed" of a unicorn can be measured by the time it takes to reach this valuation.
                </div>
            </div>
            </div>
            <div className="chat-main-sender-sec">
                <div className="chat-main-sender-icon-sec">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#195097"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                >
                    <circle cx="256" cy="128" r="128"></circle>
                    <path d="M256 298.667c-105.99.118-191.882 86.01-192 192C64 502.449 73.551 512 85.333 512h341.333c11.782 0 21.333-9.551 21.333-21.333-.117-105.99-86.009-191.883-191.999-192z"></path>
                </svg>
                </div>
                <div className="chat-main-sender-message-sec">
                <div className="chat-main-sender-message">
                    The advantages of Artificial Intelligence
                </div>
                <Button className="chat-main-sender-edit-btn">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                        fill="#9DA3AE"
                        fillRule="evenodd"
                        d="M3.25 22a.75.75 0 01.75-.75h16a.75.75 0 010 1.5H4a.75.75 0 01-.75-.75z"
                        clipRule="evenodd"
                        ></path>
                        <path
                        fill="#9DA3AE"
                        d="M11.52 14.929l5.917-5.917a8.232 8.232 0 01-2.661-1.787 8.232 8.232 0 01-1.788-2.662L7.07 10.48c-.462.462-.693.692-.891.947a5.24 5.24 0 00-.599.969c-.139.291-.242.601-.449 1.22l-1.088 3.267a.848.848 0 001.073 1.073l3.266-1.088c.62-.207.93-.31 1.221-.45.344-.163.669-.364.969-.598.255-.199.485-.43.947-.891zM19.079 7.37a3.146 3.146 0 00-4.45-4.449l-.71.71.031.09c.26.749.751 1.732 1.674 2.655A7.003 7.003 0 0018.37 8.08l.71-.71z"
                        ></path>
                    </svg>
                </Button>
                </div>
            </div>
            <div className="chat-main-receiver-sec">
            <div className="chat-main-receiver-icon-sec">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="20" height="20" data-name="Layer 1" viewBox="0 0 24 24"><path d="M19.5 24a1 1 0 01-.929-.628l-.844-2.113-2.116-.891a1.007 1.007 0 01.035-1.857l2.088-.791.837-2.092a1.008 1.008 0 011.858 0l.841 2.1 2.1.841a1.007 1.007 0 010 1.858l-2.1.841-.841 2.1A1 1 0 0119.5 24zM10 21a2 2 0 01-1.936-1.413L6.45 14.54l-5.063-1.694a2.032 2.032 0 01.052-3.871l5.023-1.534 1.692-5.054A1.956 1.956 0 0110.108 1a2 2 0 011.917 1.439l1.532 5.015 5.03 1.61a2.042 2.042 0 010 3.872l-5.039 1.612-1.612 5.039A2 2 0 0110 21zM20.5 7a1 1 0 01-.97-.757l-.357-1.43-1.433-.385a1 1 0 01.034-1.94l1.4-.325.356-1.406a1 1 0 011.94 0l.354 1.418 1.418.355a1 1 0 010 1.94l-1.418.355-.354 1.418A1 1 0 0120.5 7z"></path></svg>
            </div>
            <div className="chat-main-receiver-message-sec">
                <div className="chat-main-receiver-message">
                    Unfortunately, the question "What's the average speed of a unicorn?" has no definitive answer as unicorns are mythical creatures. While they are often depicted as swift and graceful, there is no scientific data to confirm their actual speed.
                    However, we can explore some possible interpretations of the question:
                    1. Unicorns in Myth and Legend:
                    In mythology and folklore, unicorns are often described as being very fast. They are frequently depicted galloping across meadows or leaping over obstacles. However, there are no specific references to their exact speed.
                    Some literary works, like the Chronicles of Narnia, have attempted to quantify their speed. For example, in The Horse and His Boy, a unicorn is described as being able to outpace a group of horses.
                    Based on these descriptions, we can make an educated guess that unicorns are likely much faster than average horses, which can reach speeds of up to 55 mph.
                    2. Unicorns as Startup Companies:
                    In modern business terminology, "unicorn" refers to a startup company with a valuation of over $1 billion.
                    The "speed" of a unicorn can be measured by the time it takes to reach this valuation.
                </div>
            </div>
            </div>
            <div className="chat-main-sender-sec">
                <div className="chat-main-sender-icon-sec">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#195097"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                >
                    <circle cx="256" cy="128" r="128"></circle>
                    <path d="M256 298.667c-105.99.118-191.882 86.01-192 192C64 502.449 73.551 512 85.333 512h341.333c11.782 0 21.333-9.551 21.333-21.333-.117-105.99-86.009-191.883-191.999-192z"></path>
                </svg>
                </div>
                <div className="chat-main-sender-message-sec">
                <div className="chat-main-sender-message">
                    The advantages of Artificial Intelligence
                </div>
                <Button className="chat-main-sender-edit-btn">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                        fill="#9DA3AE"
                        fillRule="evenodd"
                        d="M3.25 22a.75.75 0 01.75-.75h16a.75.75 0 010 1.5H4a.75.75 0 01-.75-.75z"
                        clipRule="evenodd"
                        ></path>
                        <path
                        fill="#9DA3AE"
                        d="M11.52 14.929l5.917-5.917a8.232 8.232 0 01-2.661-1.787 8.232 8.232 0 01-1.788-2.662L7.07 10.48c-.462.462-.693.692-.891.947a5.24 5.24 0 00-.599.969c-.139.291-.242.601-.449 1.22l-1.088 3.267a.848.848 0 001.073 1.073l3.266-1.088c.62-.207.93-.31 1.221-.45.344-.163.669-.364.969-.598.255-.199.485-.43.947-.891zM19.079 7.37a3.146 3.146 0 00-4.45-4.449l-.71.71.031.09c.26.749.751 1.732 1.674 2.655A7.003 7.003 0 0018.37 8.08l.71-.71z"
                        ></path>
                    </svg>
                </Button>
                </div>
            </div>
            <div className="chat-main-receiver-sec">
            <div className="chat-main-receiver-icon-sec">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="20" height="20" data-name="Layer 1" viewBox="0 0 24 24"><path d="M19.5 24a1 1 0 01-.929-.628l-.844-2.113-2.116-.891a1.007 1.007 0 01.035-1.857l2.088-.791.837-2.092a1.008 1.008 0 011.858 0l.841 2.1 2.1.841a1.007 1.007 0 010 1.858l-2.1.841-.841 2.1A1 1 0 0119.5 24zM10 21a2 2 0 01-1.936-1.413L6.45 14.54l-5.063-1.694a2.032 2.032 0 01.052-3.871l5.023-1.534 1.692-5.054A1.956 1.956 0 0110.108 1a2 2 0 011.917 1.439l1.532 5.015 5.03 1.61a2.042 2.042 0 010 3.872l-5.039 1.612-1.612 5.039A2 2 0 0110 21zM20.5 7a1 1 0 01-.97-.757l-.357-1.43-1.433-.385a1 1 0 01.034-1.94l1.4-.325.356-1.406a1 1 0 011.94 0l.354 1.418 1.418.355a1 1 0 010 1.94l-1.418.355-.354 1.418A1 1 0 0120.5 7z"></path></svg>
            </div>
            <div className="chat-main-receiver-message-sec">
                <div className="chat-main-receiver-message">
                    Unfortunately, the question "What's the average speed of a unicorn?" has no definitive answer as unicorns are mythical creatures. While they are often depicted as swift and graceful, there is no scientific data to confirm their actual speed.
                    However, we can explore some possible interpretations of the question:
                    1. Unicorns in Myth and Legend:
                    In mythology and folklore, unicorns are often described as being very fast. They are frequently depicted galloping across meadows or leaping over obstacles. However, there are no specific references to their exact speed.
                    Some literary works, like the Chronicles of Narnia, have attempted to quantify their speed. For example, in The Horse and His Boy, a unicorn is described as being able to outpace a group of horses.
                    Based on these descriptions, we can make an educated guess that unicorns are likely much faster than average horses, which can reach speeds of up to 55 mph.
                    2. Unicorns as Startup Companies:
                    In modern business terminology, "unicorn" refers to a startup company with a valuation of over $1 billion.
                    The "speed" of a unicorn can be measured by the time it takes to reach this valuation.
                </div>
            </div>
            </div>
            <div className="chat-main-message-action-btn-sec">
            <Button className="chat-main-message-action-btn">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#2A2A2A"
                x="0"
                y="0"
                enableBackground="new 0 0 512 512"
                version="1.1"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
                >
                <path d="M256 448c-82.436-.094-149.239-66.898-149.333-149.333v-192c0-4.267.277-8.533.64-12.629-39.168 16.826-64.576 55.333-64.64 97.962v213.333C42.737 464.214 90.452 511.93 149.333 512h128c42.629-.064 81.136-25.473 97.963-64.64-4.267.363-8.363.64-12.629.64H256zM451.307 69.803l-48.725-50.325A64.01 64.01 0 00384 6.4v78.933h78.08a63.723 63.723 0 00-10.773-15.53z"></path>
                <path d="M362.667 85.333c0 11.782 9.551 21.333 21.333 21.333h84.608a63.6 63.6 0 00-17.301-36.864l-48.725-50.325A64.24 64.24 0 00362.667.618v84.715z"></path>
                <path d="M320 85.333V0h-64c-58.881.071-106.596 47.786-106.667 106.667v192c.071 58.881 47.786 106.596 106.667 106.667h106.667c58.881-.071 106.596-47.786 106.667-106.667V149.333H384c-35.346 0-64-28.653-64-64z"></path>
                </svg>
                Copy
            </Button>
            <Button className="chat-main-message-action-btn">
                <svg
                width="20"
                height="20"
                fill="#2A2A2A"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                >
                <path d="M22.065 9.002a10.508 10.508 0 00-7.067-7.067A2.002 2.002 0 0013 0h-2a2.001 2.001 0 00-1.998 1.935 10.508 10.508 0 00-7.067 7.067A2.002 2.002 0 000 11v2c0 1.081.862 1.964 1.935 1.998a10.508 10.508 0 007.067 7.067A2.002 2.002 0 0011 24h2a2.001 2.001 0 001.998-1.935 10.508 10.508 0 007.067-7.067A2.002 2.002 0 0024 13v-2a2.001 2.001 0 00-1.935-1.998zM11 2h2v2h-2V2zM4 13H2v-2h2v2zm7 9v-2h2v2h-2zm8.956-7.001a8.506 8.506 0 01-4.957 4.957A2.001 2.001 0 0013 18h-2a2.001 2.001 0 00-1.999 1.956 8.506 8.506 0 01-4.957-4.957A2.001 2.001 0 006 13v-2a2.001 2.001 0 00-1.956-1.999 8.506 8.506 0 014.957-4.957A2.001 2.001 0 0011 6h2a2.001 2.001 0 001.999-1.956 8.506 8.506 0 014.957 4.957A2.001 2.001 0 0018 11v2c0 1.088.873 1.976 1.956 1.999zM22 13h-2v-2h2v2z"></path>
                </svg>
                Regenerate Response
            </Button>
            </div>
        </div>
        <div className="chat-main-wrapper-footer-sec">
        <Form className="chat-main-wrapper-typing-form">
            <div className="chat-main-wrapper-typing-box">
            <div className="chat-main-wrapper-footer-input-sec">
                <Form.Group className="mb-0" controlId="exampleForm.ControlInput1">
                <Form.Control type="text" placeholder="Ask or search anything..." />
                </Form.Group>
            </div>
            <div className="chat-main-wrapper-footer-submit-sec">
                <Button className="chat-main-wrapper-footer-submit-btn">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    fill="#fff"
                    height="20"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                >
                    <path d="M.172 3.708A2.745 2.745 0 01.917.713 2.745 2.745 0 013.965.282l18.09 8.444A3.584 3.584 0 0124 10.999H4.048L.229 3.835a1.446 1.446 0 01-.057-.127zM4.062 13L.309 20.175a.944.944 0 00-.054.122 2.744 2.744 0 00.749 2.993 2.756 2.756 0 001.841.708c.409 0 .819-.092 1.201-.279l18.011-8.438a3.582 3.582 0 001.945-2.28H4.062z"></path>
                </svg>
                Submit
                </Button>
            </div>
            </div>
        </Form>
        </div>
    </div>
              
    </>
  );
};

export default ChatMobileMainWrapper;
