import React from "react";
import {Form, Button, Image, Modal} from "react-bootstrap";

const RenameThreadModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center rename-thread-modal"
        size="md"
        centered
        show={props.renameThread}
        onHide={props.closeRenameThreadModal}
      >
          <Modal.Body>
          <h4>Rename Thread</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeRenameThreadModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 11 11"
            >
              <path
                fill="#979BA2"
                d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
              ></path>
            </svg>
          </Button>
          <div className="rename-thread-form-sec">
            <Form className="rename-thread-form">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control type="text" placeholder="Thread Name" />
                </Form.Group>
                <div className="rename-thread-btn-sec">
                  <Button className="default-grey-btn"  onClick={() => props.closeRenameThreadModal()}>
                    Cancel
                  </Button>
                  <Button className="default-btn">
                    Rename
                  </Button>
                </div>
            </Form>
          </div>
          </Modal.Body>
      </Modal>
    </>
  );
};

export default RenameThreadModal;