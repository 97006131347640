import React from "react";
import { Image } from "react-bootstrap";

const NoDataFound = () => {
  return (
    <>
      <div className="no-bot-exists-card">
        <div className="no-bot-exists-img-sec">
          <Image
            className="no-bot-exists-img"
            src={
              window.location.origin + "/img/nodata-found-img.png"
            }
          />
        </div>
        <div className="no-bot-exists-info">
          <h4>No Data Found</h4>
        </div>
      </div>
    </>
  );
};

export default NoDataFound;
