import React, { useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../Helper/NoDataFound";
import { trainedFileInfoStart } from "../../store/slices/CommonSlice";

const FileUploadingProcess = (props) => {
  const dispatch = useDispatch();
  const trainedFileInfo = useSelector((state) => state.common.trainedFileInfo);
  const trainLLM = useSelector((state) => state.common.trainLLM);
  const userId = useSelector((state) => state.common.userId);
  const [skipRender, setSkipRender] = React.useState(true);

  useEffect(() => {
    if (!skipRender && Object.keys(trainedFileInfo.data).length > 0) {
      props.setStep(1);
    }
    setSkipRender(false);
  }, [trainedFileInfo]);

  return (
    <>
      <div className="file-uploading-processing-frame">
        {trainedFileInfo.buttonDisable ? (
          <>
            <Skeleton circle height={150} width={150} />
            <Skeleton height={20} width={200} />
            <Skeleton height={50} width={200} />
          </>
        ) : Object.keys(trainedFileInfo.data).length > 0 ? (
          <React.Fragment>
            {trainLLM.loading ? (
              <div className="processing-frame">
                <Image
                  className="btn-loader"
                  src={window.location.origin + "/img/btn-loader.gif"}
                />
                <span>Training Initiated...</span>
              </div>
            ) : (
              <>
                <div className="file-upload-initisat-status">
                  <span className="file-initisat-success">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      x="0"
                      y="0"
                      enableBackground="new 0 0 507.506 507.506"
                      version="1.1"
                      viewBox="0 0 507.506 507.506"
                      xmlSpace="preserve"
                      fill="#195097"
                    >
                      <path d="M163.865 436.934a54.228 54.228 0 01-38.4-15.915L9.369 304.966c-12.492-12.496-12.492-32.752 0-45.248 12.496-12.492 32.752-12.492 45.248 0l109.248 109.248L452.889 79.942c12.496-12.492 32.752-12.492 45.248 0 12.492 12.496 12.492 32.752 0 45.248L202.265 421.019a54.228 54.228 0 01-38.4 15.915z"></path>
                    </svg>
                  </span>
                </div>
                <span className="training-msg">Training Completed Successfully.</span>
              </>
            )}
            {/* <div className="file-uploading-total-frame">
              <div className="file-total-done-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  x="0"
                  y="0"
                  enableBackground="new 0 0 507.506 507.506"
                  version="1.1"
                  viewBox="0 0 507.506 507.506"
                  xmlSpace="preserve"
                  fill="#195097"
                >
                  <path d="M163.865 436.934a54.228 54.228 0 01-38.4-15.915L9.369 304.966c-12.492-12.496-12.492-32.752 0-45.248 12.496-12.492 32.752-12.492 45.248 0l109.248 109.248L452.889 79.942c12.496-12.492 32.752-12.492 45.248 0 12.492 12.496 12.492 32.752 0 45.248L202.265 421.019a54.228 54.228 0 01-38.4 15.915z"></path>
                </svg>
                <span>{trainedFileInfo.data.data.trained_file_names.length} Files </span>
              </div>
              <div className="file-total-fail-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="#FF6060"
                >
                  <path d="M14.121 12L18 8.117A1.5 1.5 0 0015.883 6L12 9.879 8.11 5.988A1.5 1.5 0 105.988 8.11L9.879 12 6 15.882A1.5 1.5 0 108.118 18L12 14.121 15.878 18A1.5 1.5 0 0018 15.878z"></path>
                </svg>
                <span>{trainedFileInfo.data.data.non_trained_file_names.length} Files</span>
              </div>
            </div> */}
            <div className="file-uploading-processing-action text-center">
              <Button
                className="file-processing-btn"
                disabled={trainedFileInfo.buttonDisable}
                onClick={() =>
                  dispatch(
                    trainedFileInfoStart({
                      user_id: userId,
                      bot_id: userId,
                    })
                  )
                }
              >
                {trainedFileInfo.buttonDisable ? "Loading" : "Refresh Status"}
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};

export default FileUploadingProcess;
