import axios from "axios";

export const apiUrl = `http://0.0.0.0:5003/`;
export const queryUrl = `http://0.0.0.0:5004/`
export const serviceUrl = `http://0.0.0.0:5005/`;

const Environment = {
    postMethod: async (action, object, baseUrl = apiUrl) => {
        const url = baseUrl + action;

        let formData = new FormData();
        for (var key in object)
            formData.append(key, object[key]);

        const accessToken = localStorage.getItem("token") ?
            localStorage.getItem("token")
            : sessionStorage.getItem("token") ?
                sessionStorage.getItem("token")
                : "";
        let config = { headers: { Authorization: 'Bearer ' + accessToken } };
        return await axios.post(url, formData, config);
    },
    getMethod: async (action, baseUrl = apiUrl) => {
        const url = baseUrl + action;
        const accessToken = localStorage.getItem("token") ?
            localStorage.getItem("token")
            : sessionStorage.getItem("token") ?
                sessionStorage.getItem("token")
                : "";
        let config = { headers: { Authorization: 'Bearer ' + accessToken } };
        return await axios.get(url, config);
    },
    servicePostMethod: async (action, baseUrl = serviceUrl) => {
        const url = baseUrl + action;

        const accessToken = localStorage.getItem("token") ?
            localStorage.getItem("token")
            : sessionStorage.getItem("token") ?
                sessionStorage.getItem("token")
                : "";
        let config = { headers: { Authorization: 'Bearer ' + accessToken } };
        return await axios.post(url, config);
    },
    queryPostMethod: async (action, object, baseUrl = queryUrl) => {
        const url = baseUrl + action;

        let formData = new FormData();
        for (var key in object)
            formData.append(key, object[key]);

        const accessToken = localStorage.getItem("token") ?
            localStorage.getItem("token")
            : sessionStorage.getItem("token") ?
                sessionStorage.getItem("token")
                : "";
        let config = { headers: { Authorization: 'Bearer ' + accessToken } };
        return await axios.post(url, formData, config);
    },
};

export default Environment;
